<template>
  <app-layout heading="Sign in"
              :breadcrumb="breadcrumb"
  >
    <div class="w-full text-black flex justify-center items-center my-20">
      <div class="w-[95%] lg:w-[40%] flex justify-center flex-col">
        <div class="w-full flex justify-start">
          <span v-show="isError"
                class="text-red-500"
          >{{ errorMsg }}</span>
        </div>
        <div class="h-[20%] w-full flex flex-col justify-end items-center">
          <div class="w-full">
            <small
              v-for="(error, index) of v$.formData.email.$errors"
              :key="index"
              class="text-red-600 pl-3"
            >
              {{ error.$message }}
            </small>
          </div>
          <div class="relative flex w-full items-center">
            <input
              v-model="formData.email"
              :class="getClass(v$.formData.email.$error)"
              type="text"
              :placeholder="
                v$.formData.email.$error
                  ? v$.formData.email.$errors[0].$message
                  : 'enter email'
              "
              class="w-full border-b-[2px] border-[#aaa] outline-none p-2 md:p-4 pl-10 md:pl-14 box-border duration-300 focus:boder-[#eb6d6d] rounded-lg focus:shadow-slate-500 focus:shadow-xl"
              @keyup.enter="submitForm"
            >
            <img
              src="../../assets/Image/RegisterImg-icon/user-icon-2.png"
              class="mr-2 absolute left-0 pl-3"
            >
          </div>
        </div>

        <div
          class="h-[20%] w-full flex flex-col justify-end items-center my-12"
        >
          <div class="relative flex w-full items-center">
            <input
              v-model="formData.password"
              :type="passwordInputType"
              :class="getClass(v$.formData.password.$error)"
              class="w-full border-b-[2px] border-[#aaa] outline-none p-2 md:p-4 pl-10 md:pl-14 box-border duration-300 focus:boder-[#eb6d6d] rounded-lg focus:shadow-slate-500 focus:shadow-xl"
              :placeholder="
                v$.formData.password.$error
                  ? v$.formData.password.$errors[0].$message
                  : 'enter password'
              "
              @keyup.enter="submitForm"
            >
            <img
              src="../../assets/Image/RegisterImg-icon/password-icon.png"
              class="mr-2 absolute left-0 px-2"
            >
            <img
              class="mr-2 absolute w-[40px] right-0 px-2 cursor-pointer"
              :src="require(`@/assets/Image/RegisterImg-icon/${eyeImag}`)"
              @click="togglePasswordVisibility"
            >
          </div>
        </div>

        <div class="w-full flex justify-between items-center">
          <span
            class="underline text-sm cursor-pointer"
            @click="$router.push({ name: 'Signup' })"
          >Create an account</span>
          <button
            class="bg-secondary px-4 py-2 text-white rounded-lg"
            @click="submitForm"
          >
            Signin
            <svg
              class="fill-[#ffffff] w-[15px] ml-2 inline"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </app-layout>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import {
  showToast,
  togglePasswordVisibility,
  getClass,
} from "../Utility/utils";

export default {
  data () {
    return {
      v$: useValidate(),
      breadcrumb: ["Home", "Signin"],
      formData: {
        email: "",
        password: "",
      },
      eyeImag: "hide-password.png",
      passwordInputType: "password",
      isError: false,
      errorMsg: "",
      isLoading: false,
    };
  },
  computed: {
    getClass,
  },
  validations () {
    return {
      formData: {
        email: { required, email },
        password: { required },
      },
    };
  },
  methods: {
    togglePasswordVisibility,
    async submitForm () {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.isLoading = true;
      let signInResponse = await this.$store.dispatch("signin", this.formData);
      if (signInResponse.status === 200) {
        this.isError = false;

        await this.$store.dispatch("profile");
        let response = await this.$store.getters.profile;
        showToast(
          "info",
          `Welcome ${response.first_name + " " + response.last_name}`,
        );
        this.isLoading = false;
        this.$router.go(-1);
        return;
      } else if (signInResponse.status === 401) {
        this.isError = true;
        this.errorMsg = signInResponse.data.detail;
        this.isLoading = false;
      } else if (signInResponse.status === 500) {
        this.isError = true;
        this.errorMsg = "Internal Server Error";
        this.isLoading = false;
      }
    },
  },
};
</script>
