<template>
  <div class="bg-gray-900">
    <div
      class="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
    >
      <div class="grid row-gap-10 mb-8 lg:grid-cols-6">
        <div
          class="grid grid-cols-3 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-3"
        >
          <div>
            <p class="font-medium tracking-wide text-gray-300">
              Shop
            </p>
            <ul class="mt-2 space-y-2">
              <li>
                <router-link
                  to="#shop"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Supplements
                </router-link>
              </li>
              <li>
                <router-link
                  to="#shop"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Gym Accessories
                </router-link>
              </li>
              <li>
                <router-link
                  to="#shop"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Whey Protein
                </router-link>
              </li>
              <li>
                <router-link
                  to="#shop"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Vitamins
                </router-link>
              </li>
            </ul>
          </div>
          <div>
            <p class="font-medium tracking-wide text-gray-300">
              Personal Training
            </p>
            <ul class="mt-2 space-y-2">
              <li>
                <router-link
                  to="#service"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  12 Week Challenge
                </router-link>
              </li>
              <li>
                <router-link
                  to="#service"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Posing Lesson
                </router-link>
              </li>
              <li>
                <router-link
                  to="#service"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Bulk Program
                </router-link>
              </li>
              <li>
                <router-link
                  to="#service"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Shredded Program
                </router-link>
              </li>
              <li>
                <router-link
                  to="#service"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  1:1 Coaching
                </router-link>
              </li>
            </ul>
          </div>
          <div>
            <p class="font-medium tracking-wide text-gray-300">
              Important
            </p>
            <ul class="mt-2 space-y-2">
              <li>
                <a
                  href="/"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >FAQ</a>
              </li>
              <li>
                <router-link
                  to="/shop"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Shop
                </router-link>
              </li>
              <li>
                <router-link
                  to="/privacyPolicy"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Policy
                </router-link>
              </li>
              <li>
                <router-link
                  to="#contact"
                  class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                >
                  Contact
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="md:max-w-md lg:col-span-2">
          <span class="text-base font-medium tracking-wide text-gray-300">About me</span>
          <p class="mt-4 text-sm text-gray-500">
            Alessandro, an Italian athlete born in Brescia on 25 May 1991, began
            his path in the fitness and bodybuilding field as a personal
            trainer, then approached the competitive world later, obtaining the
            title of a professional athlete in the Men's Physique category on 27
            October 2018 in Germany.
          </p>
        </div>
      </div>
      <div
        class="flex flex-col justify-between pt-5 pb-10 border-t border-gray-800 sm:flex-row"
      >
        <p class="text-sm text-gray-500">
          © {{ new Date().getFullYear() }} All Rights Reserved | Made with ❤ by
          <a href="https://algoson.com/"
             target="_blank"
          >Algoson Software</a>
        </p>
        <div class="flex items-center mt-4 space-x-4 sm:mt-0">
          <a
            class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            href="https://www.youtube.com/user/VGGHKJH"
            target="_blank"
            aria-label="youtube"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6"
              fill="currentColor"
              viewBox="0 0 576 512"
            >
              <path
                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/_alessandrocavagnola/"
            target="_blank"
            class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
          >
            <svg viewBox="0 0 30 30"
                 fill="currentColor"
                 class="h-6"
            >
              <circle cx="15"
                      cy="15"
                      r="4"
              />
              <path
                d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/Alessadro.Cavagnola/"
            target="_blank"
            class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
          >
            <svg viewBox="0 0 24 24"
                 fill="currentColor"
                 class="h-5"
            >
              <path
                d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
