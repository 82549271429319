<template>
  <section
    class="pt-20 lg:pt-[100px] pb-20 lg:pb-[70px] relative min-h-[100vh]"
    :class="[bgColor]"
  >
    <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
      <div class="flex flex-wrap justify-center">
        <div class="w-full px-4">
          <div class="mb-12 lg:mb-20 max-w-[620px]">
            <h2 class="font-bold text-xl text-secondary mb-2 block">
              {{ heading }}
              <span
                v-if="archiveLink"
                class="font-normal text-sm text-white hover:text-blue-400 cursor-pointer"
                @click="$router.push({ name: archiveLink })"
              >
                (View all)
              </span>
              <a v-if="hrefText"
                 target="_blank"
                 :href="href"
              >
                <span
                  class="font-normal text-xl text-white hover:text-blue-400 cursor-pointer"
                >
                  {{ hrefText }}
                </span>
              </a>
            </h2>
            <p
              class="text-lg sm:text-lg leading-relaxed sm:leading-relaxed text-[#d3d3d3]"
            >
              {{ description }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <slot />
        </div>
      </div>
      <img
        :src="require(`@/assets/Icon-Symbol/${watermarkImage}`)"
        class="absolute right-6 bottom-6 w-[30px] opacity-40"
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "AppNewSection",
  components: {},
  props: {
    bgColor: {
      type: String,
      default: "bg-black",
    },
    heading: {
      required: false,
      type: String,
      default: "",
    },
    description: {
      required: false,
      type: String,
      default: "",
    },
    descriptioncolor: {
      type: String,
      default: "text-white",
    },
    headingcolor: {
      type: String,
      default: "text-white",
    },
    darkWatermark: {
      type: Boolean,
      required: false,
    },
    archiveLink: {
      type: String,
      required: false,
      default: "",
    },
    href: {
      type: String,
      required: false,
      default: "",
    },
    hrefText: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    watermarkImage () {
      return this.darkWatermark
        ? "Symbol-logo-black.png"
        : "Symbol-logo-white.png";
    },
  },
};
</script>
