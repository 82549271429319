<template>
  <appSection
    id="social"
    bg-color="bg-[#2b2e35]"
    heading="Follow me on Instagram"
    heading-color="text-white"
    href="https://www.instagram.com/_alessandrocavagnola/?hl=en"
    href-text="@alessandrocavagnola"
  >
    <div
      id="instafeed-container"
      class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 place-items-center place-content-center"
    />
  </appSection>
</template>

<script>
export default {
  name: "SocialMediaSection",
  mounted () {
    var userFeed = new Instafeed({
      get: "user",
      target: "instafeed-container",
      limit: 4,
      resolution: "standard_resolution",
      accessToken:
        "IGQVJXWkNWdF96WXpyV0JKSWZAnYms5TkFGNFYtalR2MUJwUXZA2SkQ5TjYtUlBTRHdCdGc3V3FMTV9oSmlHQzYwTFlYdWUzOG5UUEhMa0xOWTczTGllc2xmY0xSRHdtdkRPc3RxNUhiTlpfdzNScWdWWQZDZD",
      template:
        "<div  class='relative social-card h-full lg:w-[100%] cursor-pointer' ><img src='{{image}}' class='w-[380px] h-[375px]'><div class='absolute top-0 left-0 w-[100%] h-[100%] flex justify-center bg-[#00000099] transition-opacity duration-300 ease-in items-center opacity-0 hover:opacity-100'><a class='text-white' href='{{link}}' target='_blank' id={{id}}'><img  href='{{link}}' target='_blank' id={{id}}' src='https://alessandrocavagnola.com/static/media/more-services-coming-soon/instagram-new.png'/></div></div>",
    });
    userFeed.run();
  },
};
</script>
