import { createApp } from "vue";
import App from "./App.vue";
import AppSection from "./components/layout/AppSection.vue";
import AppNav from "./components/layout/AppNav.vue";
import AppFooter from "./components/layout/AppFooter.vue";
import AppLayout from "./components/layout/AppLayout.vue";
import Modal from "./components/Alert/Modal.vue";
import Spinner from "./components/Alert/Spinner.vue";
import router from "./router";
import store from "./stores/index";

import "./assets/style.css";
import "./axios.js";

const app = createApp(App);
app.use(router);
app.use(store);
app.component("Modal", Modal);
app.component("Spinner", Spinner);
app.component("AppSection", AppSection);
app.component("AppLayout", AppLayout);
app.component("AppNav", AppNav);
app.component("AppFooter", AppFooter);
app.mount("#app");
