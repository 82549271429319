<template>
  <app-layout :breadcrumb="breadcrumb"
              heading="Success"
  >
    >
    <div class="rounded-3xl w-[100%] py-20 flex justify-center">
      <div
        class="w-[90%] md:w-[45%] shadow-2xl py-8 flex justify-center flex-col items-center"
      >
        <div
          class="gradient w-[50px] h-[50px] rounded-full flex flex-col justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            class="w-[20px] fill-[#ffff]"
          >
            <path
              d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
            />
          </svg>
        </div>
        <span class="text-2xl text-green-300 font-bold my-5">Payement Successfull!</span>

        <router-link
          class="gradient bg-red-500 py-2 px-4 rounded-xl"
          to="/services"
        >
          Check Your Purchased Services
        </router-link>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/layout/AppLayout.vue";
export default {
  name: "SuccessURL.vue",

  components: { AppLayout },
  data () {
    return {
      breadcrumb: ["Home", "Sucess Payment"],
    };
  },
  methods: {},
};
</script>

<style scoped>
.gradient {
  background: #1f4037; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #99f2c8,
    #1f4037
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #99f2c8,
    #1f4037
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
