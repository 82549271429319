<template>
  <app-layout :breadcrumb="breadcrumb"
              heading="checkout"
  >
    <div
      v-show="items.length > 0"
      class="w-[90%] md:w-full lg:w-[90%] 2xl:w-full mx-auto my-14 overflow-hidden"
    >
      <span class="text-black text-4xl"> Billing Details </span>
    </div>
    <div
      v-if="items.length > 0"
      class="parent mx-auto w-[90%] md:w-full xl:w-[90%] 2xl:w-full flex flex-col md:flex-row justify-between mb-16"
    >
      <form class="w-full md:w-[50%] text-black">
        <div class="flex justify-between">
          <div class="w-[49%]">
            <label class="font-medium">First name <span class="text-red-500">*</span></label>
            <small
              v-for="(error, index) of v$.formData.firstName.$errors"
              :key="index"
              class="text-red-600 px-2"
            >
              {{ error.$message }}
            </small>
            <input
              v-model="formData.firstName"
              type="text"
              class="border-2 border-gray-300 py-1 md:py-2 mt-2 px-4 w-full rounded-lg outline-none"
            >
          </div>
          <div class="w-[49%]">
            <label class="font-medium">Last name <span class="text-red-500">*</span></label>
            <small
              v-for="(error, index) of v$.formData.lastName.$errors"
              :key="index"
              class="text-red-600 px-2"
            >
              {{ error.$message }}
            </small>
            <input
              v-model="formData.lastName"
              type="text"
              class="border-2 border-gray-300 py-1 md:py-2 mt-2 px-4 w-full rounded-lg outline-none"
            >
          </div>
        </div>

        <div class="my-6">
          <label class="font-medium"
                 for=""
          >Street address <span class="text-red-500">*</span></label>
          <small
            v-for="(error, index) of v$.formData.streetAddress.$errors"
            :key="index"
            class="text-red-600 px-2"
          >
            {{ error.$message }}
          </small>
          <input
            v-model="formData.streetAddress"
            type="text"
            class="border-2 border-gray-300 py-1 md:py-2 mt-2 px-4 w-full rounded-lg outline-none"
            placeholder="House number and steet name"
          >
          <input
            v-model="formData.optionalAddress"
            type="text"
            class="border-2 border-gray-300 py-1 md:py-2 px-4 mt-4 w-full rounded-lg outline-none"
            placeholder="Apartment,suite,unit etc.(optional)"
          >
        </div>
        <div>
          <label class="mb-2 font-medium"
                 for=""
          >Town/City <span class="text-red-500">*</span></label>
          <small
            v-for="(error, index) of v$.formData.cityName.$errors"
            :key="index"
            class="text-red-600 px-2"
          >
            {{ error.$message }}
          </small>
          <input
            v-model="formData.cityName"
            type="text"
            class="border-2 border-gray-300 py-1 md:py-2 mt-2 px-4 w-full rounded-lg outline-none"
          >
        </div>
        <div class="w-full my-6">
          <label class="mb-2 font-medium"
                 for=""
          >Country/Region <span class="text-red-500">*</span></label>
          <small
            v-for="(error, index) of v$.formData.country.$errors"
            :key="index"
            class="text-red-600 px-2"
          >
            {{ error.$message }}
          </small>

          <input
            v-model="formData.country"
            type="text"
            class="border-2 border-gray-300 py-1 md:py-2 mt-2 px-4 w-full rounded-lg outline-none"
          >
        </div>

        <div class="my-6">
          <label class="mb-2 font-medium"
                 for=""
          >PostCode <span class="text-red-500">*</span></label>
          <small
            v-for="(error, index) of v$.formData.postCode.$errors"
            :key="index"
            class="text-red-600 px-2"
          >
            {{ error.$message }}
          </small>
          <input
            v-model="formData.postCode"
            type="text"
            class="border-2 border-gray-300 py-1 md:py-2 mt-2 px-4 w-full rounded-lg"
          >
        </div>

        <div class="">
          <label class="mb-2 font-medium"
                 for=""
          >Phone <span class="text-red-500">*</span></label>
          <small
            v-for="(error, index) of v$.formData.phone.$errors"
            :key="index"
            class="text-red-600 px-2"
          >
            {{ error.$message }}
          </small>
          <input
            v-model="formData.phone"
            type="text"
            class="border-2 border-gray-300 py-1 md:py-2 mt-2 px-4 w-full rounded-lg"
          >
        </div>

        <div class="my-6">
          <label class="mb-2 font-medium"
                 for=""
          >Email <span class="text-red-500">*</span></label>
          <small
            v-for="(error, index) of v$.formData.email.$errors"
            :key="index"
            class="text-red-600 px-2"
          >
            {{ error.$message }}
          </small>

          <input
            v-model="formData.email"
            type="text"
            class="border-2 border-gray-300 py-1 md:py-2 mt-2 px-4 w-full rounded-lg"
          >
        </div>
      </form>
      <div class="w-full md:w-[40%] text-black">
        <div class="shadow-xl p-10 mx-auto bg-gray-100">
          <div
            class="w-full mt-4 uppercase font-semibold flex justify-between border-b-[1px] border-gray-300 pb-4"
          >
            <span>Product</span>
            <span>Subttotal</span>
          </div>
          <div
            v-for="(item, index) in items"
            :key="index"
            class="w-full mt-4 flex justify-between border-b-[1px] border-gray-300 pb-4 text-sm md:text-base"
          >
            <div class="w-[60%] md:w-[80%]">
              <span>{{ item.name }} </span>
              <span class="font-bold"> x {{ item.quantity }} </span>
            </div>
            <span class="font-semibold">
              ${{ (item.price * item.quantity).toFixed(2) }}</span>
          </div>
          <div
            class="w-full mt-4 flex justify-between border-b-[1px] border-gray-300 pb-4"
          >
            <span class="font-medium">Subtotal</span>
            <span class="font-semibold">${{ totalSum }}</span>
          </div>
          <div class="w-full mt-4 flex justify-between pb-4">
            <span class="font-medium">Total</span>
            <span class="text-2xl font-semibold">${{ totalSum }}</span>
          </div>
          <span class="text-sm md:text-base">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus
            consequatur iste cum commodi voluptatibus earum qui incidunt sequi
            ex
            <router-link
              to="/privacyPolicy"
              class="underline text-secondary cursor-pointer"
            >privacy policy</router-link></span>
          <button
            class="bg-secondary hover:bg-[#e62929] w-full py-3 mt-4 rounded-xl text-white"
            @click="submitForm"
          >
            Place order
          </button>
        </div>
        <Modal
          v-show="errorModal"
          msg="Something went wrong"
          img-path="error-icon.png"
          opacity="opacity-95"
        />
        <Modal
          v-show="successModal"
          msg="You Checkout Succesfully"
          img-path="checkout-success.png"
          opacity="opacity-95"
        />
      </div>
    </div>
  </app-layout>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, numeric, email } from "@vuelidate/validators";
export default {
  data () {
    return {
      v$: useValidate(),
      breadcrumb: ["Home", "Shop", "Checkout"],
      formData: {
        firstName: "",
        lastName: "",
        streetAddress: "",
        optionalAddress: "",
        cityName: "",
        postCode: "",
        phone: "",
        email: "",
        country: "",
      },
      successModal: false,
      modalVisibliity: false,
      errorModal: false,
      productDetailes: [],
      cartItem: [
        {
          name: "Optimum Nutrition  Standard  Protein ",
          price: "9.9",
          quantity: 3,
        },

        {
          name: "MyFitFuel MFF  Protein 80  1Kg",
          price: "19.9",
          quantity: 1,
        },
      ],
    };
  },
  validations () {
    return {
      formData: {
        firstName: { required },
        lastName: { required },
        streetAddress: { required },
        cityName: { required },
        postCode: { required },
        phone: { required, numeric },
        email: { required, email },
        country: { required },
      },
    };
  },
  computed: {
    totalSum () {
      return this.$store.getters.cartItems
        .reduce((accumulator, item) => {
          return accumulator + item.price * item.quantity;
        }, 0)
        .toFixed(2);
    },
    items () {
      return this.$store.getters.cartItems;
    },
  },
  methods: {
    async submitForm () {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.errorModal = true;
        // run if validation failed
        setTimeout(() => {
          this.errorModal = false;
        }, 1000);
        return;
      }
      this.successModal = true;
      setTimeout(() => {
        this.$store.dispatch("emptyCart");
        this.successModal = false;
        this.$router.push({ name: "OrderSuccess", params: this.formData });
      }, 1000);
    },
  },
};
</script>
<style scoped>
.blockclass {
  display: flex;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
</style>
