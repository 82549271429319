<template>
  <app-layout heading="Calendly"
              :breadcrumb="breadcrumb"
              :objid="ServiceID"
  >
    <section class="py-10">
      <div
        class="calendly-inline-widget"
        :data-url="url"
        style="height: 700px; width: auto; padding-bottom: 2rem"
      />
    </section>
  </app-layout>
</template>
<script>
import axios from "axios";
export default {
  data () {
    return {
      breadcrumb: ["Home", "Service"],
      ServiceID: this.$route.params.id,
      url: `https://calendly.com/alessandrocavagnola/${this.$route.params.sluggedURL}`,
    };
  },
  async created () {
    let response = await axios.get(
      `https://alessandrocavagnola.com/api/service/booking?service_id=${this.$route.params.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
        },
      },
    );
    if (response.data.slots <= 0) {
      this.$router.push({ name: "MyServices" });
    }
    const calandlyScript = document.createElement("script");
    calandlyScript.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js",
    );
    document.head.appendChild(calandlyScript);

    this.breadcrumb.push("id");
    this.breadcrumb.push("Calendly");
  },
};
</script>
