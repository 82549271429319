import { createStore } from "vuex";
import shop from "./modules/shop";
import Account from "./modules/Account";
import Services from "./modules/Services";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    shop,
    Account,
    Services,
  },
});
