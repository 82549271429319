<template>
  <appSection
    id="service"
    heading="Our Services"
    bg-color="bg-[#181A1F]"
    heading-color="text-white"
    description="Here are some top-notch services you can buy and book an appointment with Alessandro."
  >
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 place-content-center place-items-center"
    >
      <div
        v-for="(service, index) in services"
        :key="index"
        class="h-[450px] cursor-pointer relative overflow-hidden"
        @click="goToSingleService(service.name, service.id)"
      >
        <div
          class="w-full img-container h-full transition-all duration-75 ease-in-out"
        >
          <img
            class="h-full w-[400px] object-cover"
            :src="`${service.images[0]}`"
            alt="service"
          >
          <div
            class="img-overlay absolute top-0 left-0 bg-[#0000002d] hover:bg-transparent w-[400px] md:w-full h-full hover:bg-gradient-to-b from-transparent to-[#f4373781] flex items-end justify-center text-center"
          >
            <span
              id="title"
              class="text-2xl font-extrabold absolute bottom-8 uppercase mx-6"
            >
              {{ service.name }}</span>

            <span
              id="description"
              class="absolute bottom-[-60%] xl:bottom-[-50%] line-clamp overflow text-xl mx-8 md:mx-4"
            >
              {{ service.short_details }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-show="modalActive"
      msg="Currently, we are not accepting service requests."
    />
  </appSection>
</template>

<script>
export default {
  data () {
    return {
      textVisible: true,
      services: [],
      modalActive: false,
    };
  },
  async created () {
    await this.$store.dispatch("services");
    this.services = this.$store.getters.services.data;
  },
  methods: {
    bookServices () {
      this.modalActive = true;
      setTimeout(() => {
        this.modalActive = false;
      }, 1500);
    },
    goToSingleService (name, id) {
      if (name !== "More Services  Coming Soon") {
        this.$router.push({
          name: "Service",
          params: {
            id,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.img-overlay:hover #description {
  bottom: 20%;
  transition: all 0.9s ease-in-out;
}
.img-overlay:hover #title {
  margin-bottom: 0.8rem;
  bottom: 45%;
  transition: all 0.7s ease-in-out;
}
#title {
  text-shadow: 1px 2px black;
}
.img-overlay:hover {
  background-color: transparent;
}
.img-container img {
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.img-container:hover img {
  transform: scale(1.2) rotate(3deg);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 700px) and (max-width: 1200px) {
  .img-overlay:hover #description {
    bottom: 5%;
    transition: all 0.9s ease-in-out;
  }
  .img-overlay:hover #title {
    bottom: 30%;
    transition: all 0.7s ease-in-out;
  }
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}
</style>
