import axios from "axios";

const state = {
  services: [],
};

const getters = {
  services () {
    return state.services;
  },
};
const mutations = {
  setServices (state, payload) {
    state.services = payload;
  },
};

const actions = {
  async services (context) {
    try {
      let response = await axios.get("/service");
      context.commit("setServices", response);
      return response;
    } catch (error) {
      return error;
    }
  },

  async serviceContact (context, { name, email, subject, message}) {
    let data =  {
      email,
      name,
      subject,
      message,
    };
    let headers = { "Content-Type": "application/json" };
    try {
      let response = await axios.post("/service/contact-us", data, headers);
      if (response.status == 200) {
        return response;
      }
    } catch (error) {
      return error.response;
    }
  },

  async service (context,payload) {
    try {
      let response = await axios.get(`/service/${payload}`);

      return response;
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
