<template>
  <app-layout heading="Shop"
              :breadcrumb="breadcrumb"
  >
    <div v-if="products"
         class="text-black"
    >
      <div class="flex flex-col min-h-screen p-10 text-gray-800">
        <div
          class="flex flex-col sm:flex-row sm:items-end sm:justify-between mt-6 px-2"
        >
          <span class="text-sm font-semibold">1-12 of {{ totalProduct.length }} Products</span>
          <button
            class="relative text-sm focus:outline-none group mt-4 sm:mt-0"
          >
            <div
              class="flex items-center justify-between w-40 h-10 px-3 border-2 border-gray-300 rounded hover:bg-gray-300"
            >
              <span class="font-medium"> Popular </span>
              <svg
                class="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div
              class="absolute z-10 flex-col items-start hidden w-full pb-1 bg-white shadow-lg rounded group-focus:flex"
            >
              <div class="w-full px-4 py-2 text-left hover:bg-gray-200">
                Popular
              </div>
              <div class="w-full px-4 py-2 text-left hover:bg-gray-200">
                Lowest Price
              </div>
              <div class="w-full px-4 py-2 text-left hover:bg-gray-200">
                Highest Price
              </div>
            </div>
          </button>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mt-4">
          <div
            v-for="(product, index) in products"
            :key="index"
            class="overflow-hidden shadow-md rounded-lg w-72 mb-4 md:w-72 cursor-pointer m-auto"
          >
            <div class="relative">
              <img
                :src="require(`@/assets/Image/HomeImg/${product.imgUrl}`)"
                class="max-h-72 w-full object-contain bg-white p-8"
                @click="gotSingleProduct(product)"
              >
              <div class="absolute flex flex-col top-0 right-0 p-3">
                <img
                  ref="heart"
                  class="heart transition ease-in duration-300 bg-gray-500 shadow rounded-full w-8 h-8 text-center p-1"
                  :src="require(`@/assets/Image/HomeImg/${heartImage}`)"
                  @click.prevent="handleIconClick(index)"
                >
              </div>
            </div>

            <div class="bg-gray-100 w-full p-4 max-w-sm border-t-4">
              <div
                class="w-full flex-none text-sm flex items-center text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-red-500 mr-1"
                  viewBox="0 0 20 20"
                  fill="#f43738"
                >
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  />
                </svg>
                <span class="text-gray-400 whitespace-nowrap mr-3">{{
                  product.rating
                }}</span>
              </div>
              <div class="flex items-center w-full justify-between min-w-0"
                   @click="gotSingleProduct(product)"
              >
                <h2
                  class="text-lg mr-auto cursor-pointer text-black hover:text-secondary truncate"
                >
                  {{ product.name }}
                </h2>
                <div
                  class="flex items-center bg-secondary text-white text-xs px-2 py-1 ml-3 rounded-lg"
                >
                  INSTOCK
                </div>
              </div>
              <div class="text-xs text-gray-400 truncate mt-1">
                <a class="font-semibold hover:underline">
                  {{ product.category }}
                </a>
              </div>

              <div class="flex flex-row mt-2">
                <div class="flex flex-auto text-gray-600 text-lg font-semibold">
                  <p class="mr-2"
                     :class="isSales(product.offerPrice)"
                  >
                    ${{ product.price }}
                  </p>
                  <p v-if="product.offerPrice"
                     class="mr-2"
                  >
                    ${{ product.offerPrice }}
                  </p>
                </div>

                <div
                  class="flex flex-row flex-auto justify-end"
                  @click="addToCartByQuantity(product)"
                >
                  <div
                    class="flex text-xs border px-3 my-auto py-2 border-black group hover:bg-black rounded-sm transition-all duration-200 hover:fill-[#fff]"
                  >
                    <svg
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z"
                      />
                    </svg>
                    <div
                      class="text-xs text-black font-semibold ml-2 group-hover:text-white delay-100"
                    >
                      Add to Cart
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-10 space-x-1">
          <button
            class="flex items-center justify-center h-8 w-8 rounded text-gray-400"
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <button
            class="flex items-center justify-center h-8 px-2 rounded text-sm font-medium text-gray-400"
            disabled
          >
            Prev
          </button>
          <button
            class="flex items-center justify-center h-8 w-8 rounded bg-gray-300 text-sm font-medium text-indigo-800"
            disabled
          >
            1
          </button>
          <button
            class="flex items-center justify-center h-8 w-8 rounded hover:bg-gray-300 text-sm font-medium text-gray-600 hover:text-black"
          >
            2
          </button>
          <button
            class="flex items-center justify-center h-8 px-2 rounded hover:bg-gray-300 text-sm font-medium text-gray-600 hover:text-black"
          >
            Next
          </button>
          <button
            class="flex items-center justify-center h-8 w-8 rounded hover:bg-gray-300 text-gray-600 hover:text-black"
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
export default {
  name: "ShopView",
  data () {
    return {
      breadcrumb: ["Home", "Product", "Shop"],
      sortBy: "default",
      checkboxCategory: [
        "Gym Equipments",
        "Vitamins & Supplements",
        "Fitness Ball",
        "Whey Protein",
        "Sport Nutritio",
      ],
      heartImage: "heart-icon.png",
      products: false,
    };
  },
  computed: {
    isSales () {
      return (price) => {
        return {
          "line-through": price,
          "text-red-600": price,
        };
      };
    },
    productList () {
      return this.$store.getters.products.slice(0, 12);
    },
    totalProduct () {
      return this.$store.getters.products;
    },
    productSlug () {
      return (name ) => {
        return name.toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, "")
          .replace(/[\s_-]+/g, "-")
          .replace(/^-+|-+$/g, "");
      };
    },
  },
  async created () {
    this.products = await this.$store.getters.products;
  },
  methods: {
    handleIconClick (index) {
      this.isClicked = !this.isClicked;
      const heart = this.$refs.heart;
      const imagePath = this.isClicked
        ? "heart-red-icon.png"
        : "heart-icon.png";
      heart[index].src = require("@/assets/Image/HomeImg/" + imagePath);
    },
    // passing  single product as params for navigation
    gotSingleProduct (product) {
      this.$router.push({ name: "Product", params: {
        id: this.productSlug(product.name),
      },
      });
    },
    // adding product by quantity one and if product already exist showing alert
    addToCartByQuantity (product) {
      let cartitems = this.$store.getters.cartItems;
      let itemExits = cartitems.find((i) => i.name === product.name);
      let payload = { product: product, quantity: 1 };
      if (!itemExits) {
        this.$store.dispatch("addToCartByQuantity", payload);
        this.showToast("success", "Product is added in a cart");
      } else {
        this.showToast("info", "Product is already exist in cart");
      }
    },
    showToast (type, msg) {
      // const color = status === "Success" ? "green" : "#f1ff62";
      createToast(
        {
          description: msg,
        },
        {
          position:"bottom-right",
          timeout: 2000,
          type: type,
          hideProgressBar: false,
        },
      );
    },
  },
};
</script>
