import axios from "axios";


const state = {
  isLogin: false,
  name: "",
  profile:"",
  showOfferModal:true,
};

const getters = {
  isLogin: (state) => {
    return state.isLogin;
  },
  showOfferModal: (state) => {
    return state.showOfferModal;
  },

  name () {
    return state.name;
  },
  profile () {
    return state.profile;
  },
};

const mutations = {
  setIsLogin (state, payload) {
    state.isLogin = payload;
  },
  showModal (state, payload) {
    state.showOfferModal = payload;
  },

  setName (state, name) {
    state.name = name;
  },
  setProfile (state, payload){
    state.profile = payload;
  },
};

const actions = {
  showPopModal (context, payload) {
    context.commit("showModal", payload);
  },
  async signup (state, payload) {
    const data = {
      first_name: payload.first_name,
      last_name: payload.last_name,
      password: payload.password,
      email: payload.email,
    };
    try {
      let response = await axios.post("/auth/signup", data);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async signin (context, payload) {
    let data =  {
      username: payload.email,
      password: payload.password,
    };
    let headers = { "Content-Type": "application/json" };
    try {
      let response = await axios.post("/auth/signin", data, headers);
      if (response.status == 200) {
        context.commit("setIsLogin", true);
        localStorage.setItem("accesstoken", response.data.access);
        return response;
      }
    } catch (error) {
      return error.response;
    }
  },

  async tokenVerify (context, payload) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accesstoken");
      axios.post("/auth/token-verify", {token}).then( response => {
        if (response.status == 200) {
          context.commit("setIsLogin", true);
          resolve(response);
        }
      }, error => {
        reject(error);
      });
    });
  },

  async updateProfile (state, payload) {
    const data = {
      first_name: payload.first_name,
      last_name: payload.last_name,
      password: payload.password,
      email: payload.email,
      dob: payload.dob,
      street_address: payload.street_address,
      gender:payload.gender,
      city: payload.city,
      state: payload.state,
      postal_code: payload.postal_code,
      country: payload.country,
    };
    try {
      let response = await axios.put("/auth/profile", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async profile (context, payload) {
    try {
      let response = await axios.get("/auth/profile", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
        },
      });
      context.commit("setProfile", response.data.accounts);
      return response;
    } catch (error) {
      return error;
    }
  },

  async signout (context) {
    try {
      let response = await axios.get("/auth/signout", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
        },
      });

      if (response.status == 200) {
        localStorage.removeItem("accesstoken");
        localStorage.removeItem("profile");
        context.commit("setIsLogin", false);
      }
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
