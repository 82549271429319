<template>
  <OfferModal />
  <router-view v-if="isLoading" />
</template>

<script>
//import OfferModal from "@/components/Home/OfferModal.vue";
export default {
  name: "App",
  //   components: {
  //     OfferModal,
  //   },
  data () {
    return {
      isLoading: false,
    };
  },
  mounted () {
    this.$store.dispatch("updateCartFromLocalStorage");
    if (localStorage.getItem("accesstoken") !== null) {
      this.$store
        .dispatch("tokenVerify")
        .then(() => {})
        .finally(() => {
          this.isLoading = true;
        });
    } else {
      this.isLoading = true;
    }
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  color: #fff;
}
html {
  scroll-behavior: smooth;
}

.bg-primary {
  background: #20232a;
}
.text-red {
  color: #f43738;
}
.bg-red {
  background: #f43738;
}
</style>
