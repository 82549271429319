<template>
  <HeroSection />
  <AboutUs />
  <ServicesSection />
  <!-- <ShopSection /> -->
  <!-- <BlogSection /> -->
  <TestimonialSection />
  <SocialMediaSection />
  <ContactMe />
  <app-footer />
</template>

<script>
import HeroSection from "@/components/Home/HeroSection.vue";
import AboutUs from "@/components/Home/AboutUs";
import ServicesSection from "@/components/Home/ServicesSection.vue";
// import ShopSection from "@/components/Home/ShopSection.vue";
// import BlogSection from "@/components/Home/BlogSection.vue";
import TestimonialSection from "@/components/Home/TestimonialSection.vue";
import SocialMediaSection from "@/components/Home/SocialMediaSection.vue";

import ContactMe from "@/components/Home/ContactMe.vue";

export default {
  name: "HomeView",
  components: {
    HeroSection,
    AboutUs,
    ServicesSection,
    //ShopSection,
    // BlogSection,
    TestimonialSection,
    SocialMediaSection,
    ContactMe,
  },
};
</script>
