<template>
  <app-layout :breadcrumb="breadcrumb"
              heading="Profile"
  >
    <div class="flex flex-col md:flex-row w-full">
      <SideNavbar is-active="myservices" />
      <div
        v-if="myServicesEmpty"
        class="text-black w-[100%] md:w-[80%] flex justify-center items-center"
      >
        <router-link to="/"
                     @click="goToServices"
        >
          <button class="text-white px-6 py-4 rounded-md bg-red-700">
            You have no services
          </button>
        </router-link>
      </div>

      <div
        v-else
        class="mx-auto w-[100%] md:w-[80%] md:ml-20 flex flex-col text-gray-500 mt-10 md:mt-0 my-24 shadow-lg"
      >
        <div
          class="w-[100%] flex justify-between flex-col items-center md:items-start md:flex-row md:mt-10"
        >
          <table>
            <thead>
              <tr>
                <th scope="col"
                    class="w-[20%]"
                >
                  Name
                </th>
                <th scope="col"
                    class="w-[25%]"
                >
                  Purchased Date
                </th>

                <th scope="col"
                    class="w-[20%]"
                >
                  Payment Receipt
                </th>
                <th scope="col"
                    class="w-[20%]"
                >
                  Slots Booked
                </th>
                <th scope="col"
                    class="w-[20%]"
                >
                  Slots remaining
                </th>
                <th scope="col"
                    class="w-[25%]"
                >
                  Appointment
                </th>
                <th scope="col"
                    class="w-[20%]"
                >
                  Events
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(services, index) in myServices"
                  :key="index"
              >
                <td data-label="name">
                  {{ services.service_name }}
                </td>
                <td data-label="book_from">
                  {{ moment(services.purchase_date).format("LL") }}
                </td>
                <td class="container"
                    data-label="receipt_url"
                >
                  <a
                    :href="services.receipt_url"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="View Receipt"
                    class="text-black"
                  >
                    <button class="bg-gray-300 px-4 py-2 rounded-md text-sm">
                      View
                    </button>
                  </a>
                </td>
                <td data-label="slot booked ">
                  {{ services.booked }}
                </td>
                <td data-label="slot ">
                  {{ services.slots }}
                </td>
                <td class=""
                    data-label="book appointment"
                >
                  <button
                    :class="{ hidden: !services.expired }"
                    class="tooltiptext bg-gray-300 px-6 py-2 text-sm text-black rounded-md"
                    @click="
                      goToSingleService(services.service_name, services.service)
                    "
                  >
                    Buy More Slot
                  </button>
                  <button
                    id="book_appointment"
                    :disabled="services.expired"
                    :class="{ hidden: services.expired }"
                    class="bg-gray-300 px-4 py-2 text-black text-xs md:text-sm rounded-md"
                    @click="
                      goToCalendlyURL(
                        services.service,
                        services.service_name,
                        services.receipt_url
                      )
                    "
                  >
                    <span
                      :class="{ hidden: services.expired }"
                      class="tooltiptext whitespace-nowrap"
                    >Book Apointment</span>
                  </button>
                </td>
                <td class="container"
                    data-label="events"
                >
                  <button
                    class="bg-gray-300 text-black px-4 py-2 rounded-md"
                    @click="showEventsModal(services.id)"
                  >
                    <span class="whitespace-nowrap">View Events</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </app-layout>
</template>

<script>
import SideNavbar from "./SideNavbar.vue";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "MyServices.vue",
  components: {
    SideNavbar,
  },
  data () {
    return {
      breadcrumb: ["Home", "My Services"],
      myServices: "",
      isLoading: false,
      message: "",
      myServicesEmpty: false,
    };
  },
  computed: {
    serviceSlug () {
      return (name) => {
        return name
          .toLowerCase()
          .trim()
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-");
      };
    },
  },
  async created () {
    this.isLoading = true;
    let response1 = await axios.get(
      "https://alessandrocavagnola.com/api/service/booking",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
        },
      },
    );
    this.myServices = response1.data.service;
    if (this.myServices.length === 0) {
      this.myServicesEmpty = true;
    }
    this.isLoading = false;
  },
  methods: {
    moment,
    goToSingleService (name, id) {
      if (name !== "More Services  Coming Soon") {
        this.$router.push({
          name: "Service",
          params: {
            id,
          },
        });
      }
    },

    async showEventsModal (booking_id) {
      let event_response = await axios.get(
        `https://alessandrocavagnola.com/api/service/booking?booking_id=${booking_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          },
        },
      );
      var events_arr = event_response.data.service[0].events;
      // var table = "<div class='flex flex-col'><table class='border-collapse '><thead class='border-none'><tr class='border-none'><th class='border border-black border-2 text-xl' scope='col'>Start Time</th><th class='border border-black border-2 text-xl' scope='col'>End Time</th><th class='border border-black border-2 text-xl' scope='col'>Location</th></thead>";
      var table =
        "<div class='flex justify-between  bg-gray-400 py-2 text-xs md:text-base text-white px-4'><div class='w-[33%] flex justify-between'>Start Time</div><div class='w-[33%] flex justify-between'>End Time</div><div class='w-[33%]  flex justify-between'>Location</div></div>";
      var title = "<strong class='text-gray-500'>"+event_response.data.service[0].service_name+"</strong>";
      var icon = "";

      if (events_arr.length > 0) {
        const format1 = "YYYY-MM-DD hh:mm";
        for (let iter = 0; iter < events_arr.length; iter++) {
          var start_time = moment(
            events_arr[iter].start_time.split("Z")[0],
          ).format(format1);
          var end_time = moment(events_arr[iter].end_time.split("Z")[0]).format(
            format1,
          );
          start_time = moment(events_arr[iter].start_time).format(
            "YYYY-MM-DD hh:mm A",
          );
          end_time = moment(events_arr[iter].end_time).format(
            "YYYY-MM-DD hh:mm A",
          );
          // table+="<tr class='text-center'>" +
          // "<td class='text-xl whitespace-nowrap border-2 border-black px-6'>"+start_time+"</td>" +
          // "<td class='text-xl whitespace-nowrap border-2 border-black px-6'>"+end_time+"</td>" +
          // "<td class='text-xl whitespace-nowrap border-2 border-black px-6'>"+events_arr[iter].location+"</td>" +
          // "</tr>";
          table +=
            "<div class='flex justify-between   py-2 px-4 text-xs md:text-base  text-black py-4'><div class='w-[33%] flex justify-start'>" +
            start_time +
            "</div><div class='w-[33%] flex justify-start'>" +
            end_time +
            "</div><div class='w-[33%] flex justify-start'>" +
            events_arr[iter].location +
            "</div></div>";
        }
        table += "</table></div>";
      } else {
        title = "<strong>No Events Found!</strong>";
        table = "";
        icon = "error";
      }
      Swal.fire({
        title: title,
        icon: icon,
        html: table,
        focusConfirm: false,
        confirmButtonColor: "#f43738",
        width: "760px",
        confirmButtonText: "<i class='fa fa-thumbs-up'></i> Ok",
        confirmButtonAriaLabel: "Thumbs up, great!",
      });
    },

    async goToCalendlyURL (id, name, receipt_url) {
      if (!this.$store.getters.isLogin) {
        this.$router.push({ name: "Signin" });
      }
      if (this.$store.getters.isLogin) {
        let profile_attrs = this.$store.getters.profile;
        var userName = "";
        var userEmail = "";
        if (this.$store.getters.profile.first_name !== undefined) {
          userName = `${profile_attrs.first_name} ${profile_attrs.last_name}`;
          userEmail = profile_attrs.email;
        } else {
          let response = await axios.get("/auth/profile", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            },
          });
          if (response.status === 200) {
            userName = `${response.data.accounts.first_name} ${response.data.accounts.last_name}`;
            userEmail = response.data.accounts.email;
          }
        }
        let username = userName;
        let useremail = userEmail;
        let sluggedURL = this.serviceSlug(name);
        sluggedURL =
          sluggedURL +
          `?hide_landing_page_details=1&email=${useremail}&name=${username}&a1=${receipt_url}`;
        this.$router.push({ name: "Calendly", params: { id, sluggedURL } });
      }
    },
    //   if (this.$store.getters.isLogin) {
    //     let profile_attrs = this.$store.getters.profile;
    //     let username = `${profile_attrs.first_name} ${profile_attrs.last_name}`;
    //     let useremail = profile_attrs.email;
    //     let sluggedURL =
    //       this.serviceSlug(name) +
    //       `?hide_landing_page_details=1&email=${useremail}&name=${username}`;
    //     this.$router.push({ name: "Calendly", params: { id, sluggedURL } });
    //   }
    // },
  },
};
</script>

<style scoped>
table {
  /* border: 1px solid #ccc; */
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr {
  background-color: #f8f8f8;
  /* border: 1px solid #ddd; */
  padding: 0.45em;
}

table tr:not(:last-child) {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

table th {
  font-weight: bold;
  font-size: 1rem;
}
table td {
  padding: 0.625em;
  text-align: center;
}

tbal table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 1200px) {
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    /* border-bottom: 3px solid #ddd; */
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    /* border-bottom: 1px solid #ddd; */
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
