<template>
  <app-layout :breadcrumb="breadcrumb"
              heading="Profile"
  >
    <div class="flex flex-col md:flex-row w-full">
      <SideNavbar is-active="profile" />
      <div
        class="mx-auto w-[100%] md:w-[80%] md:ml-20 flex flex-col text-gray-500 mt-10 md:mt-0"
      >
        <div
          class="w-[100%] flex justify-between flex-col items-center md:items-start md:flex-row md:mt-10"
        >
          <div class="w-[90%] md:w-[40%]">
            <span class="text-xl md:text-2xl mb-10 block md:my-0 font-bold">Personal</span>
          </div>
          <div class="w-[90%]">
            <span class="block mb-2 text-gray-700">First name *</span>
            <input
              v-model="formData.first_name"
              type="text"
              class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
            >
            <span class="block mb-2 text-gray-700 mt-6">Last name *</span>
            <input
              v-model="formData.last_name"
              type="text"
              class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
            >
            <div class="flex flex-col md:flex-row justify-between mt-6">
              <div class="flex flex-col md:w-[40%] xl:w-[30%]">
                <div class="mb-2">
                  <span class="text-gray-700">Birthday *</span>
                </div>

                <input
                  v-model="formData.dob"
                  class="outline-none border-[1px] cursor-pointer border-gray-400 p-1 px-2 rounded-md w"
                  type="date"
                >
              </div>
              <div class="mt-6 md:mt-0 sm:w-[60%] md:w-[40%]">
                <span class="text-gray-700">Gender *</span>
                <div class="flex mt-2 w-[80%] justify-between">
                  <div class="md:w-[40%] flex items-center">
                    <input
                      id="male"
                      v-model="formData.gender"
                      type="radio"
                      value="m"
                      class="accentColor mr-2 cursor-pointer"
                    >
                    <label class="cursor-pointer"
                           for="male"
                    >Male</label>
                  </div>
                  <div class="md:w-[35%] flex justify-between items-center">
                    <input
                      id="female"
                      v-model="formData.gender"
                      type="radio"
                      value="f"
                      class="accentColor mr-2 md:mr-0 cursor-pointer"
                    >
                    <span for="female"
                          class="cursor-pointer block"
                    >
                      Female</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-[100%] flex justify-between flex-col items-center md:items-start md:flex-row my-12"
        >
          <div class="w-[90%] md:w-[40%]">
            <span class="text-xl md:text-2xl block mb-10 md:mb-0 font-bold">Location</span>
          </div>
          <div class="w-[90%]">
            <span class="block mb-2 text-gray-700">Street address *</span>
            <input
              v-model="formData.street_address"
              type="text"
              class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
            >

            <div class="flex flex-col md:flex-row justify-between mt-6">
              <div class="md:w-[60%]">
                <span class="block mb-2">City</span>
                <input
                  v-model="formData.city"
                  class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
                  type="text"
                >
              </div>
              <div class="md:w-[30%] mt-6 md:mt-0">
                <span class="block mb-2">Postode</span>
                <input
                  v-model="formData.postal_code"
                  class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
                  type="text"
                >
              </div>
            </div>
            <span class="block mb-2 text-gray-700 mt-6">Country *</span>
            <input
              v-model="formData.country"
              type="text"
              class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
            >
            <span class="block mb-2 text-gray-700 mt-6">State *</span>
            <input
              v-model="formData.state"
              type="text"
              class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
            >
          </div>
        </div>
        <div
          class="w-[100%] flex justify-between flex-col items-center md:items-start md:flex-row my-12"
        >
          <div class="w-[90%] md:w-[40%]">
            <span class="text-xl md:text-2xl mb-10 block md:my-0 font-bold">Contact</span>
          </div>
          <div class="w-[90%] md:w-[90%]">
            <span class="block mb-2 text-gray-700">Email *</span>
            <input
              v-model="formData.email"
              disabled
              type="text"
              class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
            >

            <label class="cursor-pointer block my-6">
              <input
                v-model="checked"
                type="checkbox"
                class="accentColor cursor-pointer mr-2"
                @click="tooglePasswordDiv"
              >
              Want to change password?</label>

            <div v-show="togglePassword"
                 class="relative w-full items-center"
            >
              <span class="block mb-2 text-gray-700">Password *</span>
              <small
                v-show="passwordNumeric"
                class="text-red-600 capitalize block mb-2"
              >{{ errorMsg }}</small>
              <small
                v-for="(error, index) of v$.formData.password.$errors"
                :key="index"
                class="text-red-600 mb-2"
              >
                {{ error.$message }}
              </small>
              <div class="flex relative w-full items-center">
                <input
                  v-model="formData.password"
                  :class="getClass(v$.formData.password.$error)"
                  :placeholder="
                    v$.formData.password.$error
                      ? v$.formData.password.$errors[0].$message
                      : '*******'
                  "
                  :type="passwordInputType"
                  class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
                >
                <img
                  :src="require(`@/assets/Image/RegisterImg-icon/${eyeImag}`)"
                  class="mr-2 absolute w-[40px] right-0 px-2 cursor-pointer"
                  @click="togglePasswordVisibility"
                >
              </div>
              <span class="block mb-2 text-gray-700 mt-6">Confirm password *</span>
              <div class="flex relative w-full items-center">
                <input
                  v-model="formData.c_password"
                  :class="getClass(v$.formData.c_password.$error)"
                  :placeholder="
                    v$.formData.c_password.$error
                      ? v$.formData.c_password.$errors[0].$message
                      : '*******'
                  "
                  :type="passwordInputType"
                  class="outline-none border-[1px] border-gray-400 p-1 px-4 w-full rounded-md"
                >
                <img
                  :src="require(`@/assets/Image/RegisterImg-icon/${eyeImag}`)"
                  class="mr-2 absolute w-[40px] right-0 px-2 cursor-pointer"
                  @click="togglePasswordVisibility"
                >
              </div>
            </div>
            <button
              class="bg-secondary hover:bg-red-600 text-white float-right px-6 mt-10 py-2 rounded-md"
              @click="submitForm"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </app-layout>
</template>

<script>
import {
  showToast,
  togglePasswordVisibility,
  isNumeric,
  getClass,
} from "../Utility/utils";
import useValidate from "@vuelidate/core";
import { email, sameAs, minLength } from "@vuelidate/validators";
import SideNavbar from "./SideNavbar.vue";
export default {
  components: {
    SideNavbar,
  },
  data () {
    return {
      breadcrumb: ["Home", "Profile"],
      v$: useValidate(),
      checked: false,
      formData: {
        first_name: "",
        last_name: "",
        dob: "",
        gender: "",
        country: "",
        city: "",
        postal_code: "",
        email: "",
        password: "",
        state: "",
        c_password: "",
        street_address: "",
        city: "",
      },
      eyeImag: "hide-password.png",
      passwordInputType: "password",
      passwordNumeric: false,
      errorMsg: "",
      isLoading: false,
    };
  },
  validations () {
    return {
      formData: {
        password: { minLength: minLength(8) },
        c_password: {
          sameAsPassword: sameAs(this.formData.password),
        },
        email: { email },
      },
    };
  },
  computed: {
    getClass,

    togglePassword () {
      return this.checked;
    },
  },
  async created () {
    this.isLoading = true;
    if (localStorage.getItem("accesstoken") !== null) {
      await this.$store.dispatch("tokenVerify");
    } else {
      this.isLoading = false;
      this.$router.push({ name: "Signin" });
      showToast("info", "Please Login First");
    }
    await this.$store.dispatch("profile");
    let response = this.$store.getters.profile;
    this.formData.first_name = response.first_name;
    this.formData.last_name = response.last_name;
    this.formData.email = response.email;
    if (
      this.formData.street_address != null &&
      this.formData.city != null &&
      this.formData.postal_code != null &&
      this.formData.gender != null &&
      this.formData.country != null
    ) {
      this.formData.dob = response.dob;
      this.formData.street_address = response.street_address;
      this.formData.country = response.country;
      this.formData.city = response.city;
      this.formData.country = response.country;
      this.formData.state = response.state;
      this.formData.postal_code = response.postal_code;
      this.formData.gender = response.gender;
    }
    this.isLoading = false;
  },
  methods: {
    tooglePasswordDIv () {
      this.checked = !this.checked;
    },
    isNumeric,
    togglePasswordVisibility,
    async submitForm () {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.isLoading = true;
      const isNumeric = this.isNumeric(this.formData.password);
      this.passwordNumeric = false;

      if (!isNumeric) {
        let response = await this.$store.dispatch(
          "updateProfile",
          this.formData,
        );
        this.isLoading = false;
        if (response.status === 200) {
          showToast("info", "Your Updated Successfully Profile is Update");
          this.isLoading = false;
          await this.$store.dispatch("profile");
        }
        return;
      } else {
        this.passwordNumeric = true;
        this.errorMsg = "password can not be entirely numeric";
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.accentColor {
  accent-color: #f43738;
}
</style>
