import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/Home/Index";
import ShopView from "../views/Shop/Index";
import CheckoutView from "../views/Shop/Checkout";
import CartView from "../views/Shop/Cart.vue";
import Signin from "../views/Accounts/Signin.vue";
import Signup from "../views/Accounts/Signup.vue";
import Product from "../views/Shop/Product.vue";
import Service from "../views/Services/Service.vue";
import Calendly from "../views/Services/Calendly.vue";
import SuccessURL from "../views/Services/SuccessURL.vue";
import CancelURL from "../views/Services/CancelURL.vue";
import OrderSuccess from "../views/Shop/OrderSuccess.vue";
import Profile from "../views/Accounts/UserProfile.vue";
import MyServices from "../views/Accounts/MyServices.vue";
import PrivacyPolicy from "../views/Accounts/PrivacyPolicy.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      title: "Professional Bodybuilder in Italy | Fitness Trainer",
      description:
        "Alessandro is a professional bodybuilder based in Italy. Alessandro Cavagnola offers a wide variety of services and also available for workout and nutrition plan.",
      keywords:
        "Alessandro, Cavagnola, fitness trainer, professional bodybuilder, bodybuilder in italy, 1-1 coaching, nutrition plan",
    },
  },
  {
    path: "/shop",
    name: "shop",
    component: ShopView,
    meta: {
      title: "Buy supplements & workout accessories",
      description:
        "From workout clothes, gym Products|equipment and active wear to fitness accessories and supplement,protein,guides, our budget-friendly workout products have all your fitness bases covered. Browse our collection!",
      keywords:
        "gym accessories, workout accessories, buy proteins in italy, purchase supplements in italy",
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      title: "Signup | My accounts",
      description:
        "create Account  to Buy gym equipment ,nutrition plan ,Supplement,Protein, Budget-friendly Fitness Gear ",
      keywords: "gym trainer, fitness trainer",
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: CheckoutView,
    meta: {
      title: "Checkout | Shop",
      description:
        "Shop Your Next Workout Product,Supplement,Protein - Budget-friendly Fitness Gear From Leading Edge Italy",
      keywords: "order supplements, buy workout accessories",
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView,
    meta: {
      title: "Cart | Shop",
      description:
        "Check your cart to see Workout Product,Supplement,Protein - Budget-friendly Fitness Gear From Leading Edge Italy",
      keywords: "fitness equipment, gym accessories",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/services",
    name: "MyServices",
    component: MyServices,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    meta: {
      title: "Sign in | My accounts",
      description: "Sign in on Alessandaro Cavagnola Website",
      keywords: "sign in, login, fitness trainer",
    },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/product/:id",
    name: "Product",
    component: Product,
  },
  {
    path: "/service/:id",
    name: "Service",
    component: Service,
  },
  {
    path: "/cancel",
    name: "CancelURL",
    component: CancelURL,
  },
  {
    path: "/success/:id",
    name: "SuccessURL",
    component: SuccessURL,
  },
  {
    path: "/service/:id/Calendly/:sluggedURL",
    name: "Calendly",
    component: Calendly,
  },
  {
    path: "/orderSuccess",
    name: "OrderSuccess",
    component: OrderSuccess,
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.fullPath.substr(0, 2) !== "/#") {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }
  if (to.meta.title) {
    document.title = `${to.meta.title} - Alessandaro Cavagnola`;
    document.getElementsByTagName("meta")["description"].content =
      to.meta.description;
    document.getElementsByTagName("meta")["keywords"].content =
      to.meta.keywords;
  } else {
    document.title =
      "Professional Bodybuilder in Italy | Fitness Trainer - Alessandaro Cavagnola";
    document.getElementsByTagName("meta")["description"].content =
      "Alessandro is a professional bodybuilder based in Italy. Alessandro Cavagnola offers a wide variety of services and also available for workout and nutrition plan.";
    document.getElementsByTagName("meta")["keywords"].content =
      "Alessandro, Cavagnola, fitness trainer, professional bodybuilder, bodybuilder in italy, 1-1 coaching, nutrition plan";
  }
  next();
});
export default router;
