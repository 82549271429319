<template>
  <appSection
    id="contact"
    heading="Contact me"
    bg-color="bg-[#181a1f]"
    description="You can contact Alessandro at the below phone number or email address. If you have any queries, please fill out the form below."
  >
    <div class="flex flex-col lg:flex-row justify-between h-full">
      <div class="w-[40%] h-full">
        <div class="pb-6">
          <img
            src="@/assets/Image/HomeImg/location-icon.png"
            class="inline w-[20px]"
          >
          <span class="pl-2 font-bold">Registered Address</span>
          <span class="mt-4 block"> Via Quinto Bevilacqua,25 </span>
          <span class="block">Milan, Itlay</span>
        </div>
        <div class="py-6">
          <img
            src="@/assets/Image/HomeImg/mobile-icon.png"
            class="inline w-[20px]"
          >
          <span class="pl-2 font-bold">Phone Number</span>
          <span class="block mt-4">+39 3343926517</span>
        </div>
        <div class="py-6">
          <img
            src="@/assets/Image/HomeImg/email-icon.png"
            class="inline w-[20px]"
          >
          <span class="pl-2 font-bold">Email</span>
          <span class="mt-4 block">ac@alessandrocavagnola.com</span>
        </div>
      </div>
      <form class="text-black"
            @submit.prevent="sendMail()"
      >
        <div class="w-full flex justify-start">
          <span v-show="isError"
                class="text-red-500"
          >{{ errorMsg }}</span>
        </div>

        <input
          v-model="formData.name"
          class="w-full md:w-[48%] px-4 py-3 outline-none"
          :class="getClass(v$.formData.name.$error)"
          type="text"
          :placeholder="
            v$.formData.name.$error
              ? v$.formData.name.$errors[0].$message
              : 'Enter name*'
          "
        >
        <div class="md:float-right md:w-[48%]">
          <input
            v-model="formData.email"
            class="w-full mt-8 md:mt-0 md:ml-6 px-4 py-3 outline-none float-right"
            :class="getClass(v$.formData.email.$error)"
            type="text"
            :placeholder="
              v$.formData.email.$error
                ? v$.formData.email.$errors[0].$message
                : 'Enter email*'
            "
          >
          <small
            v-for="(error, index) of v$.formData.email.$errors"
            :key="index"
            class="text-red-600 pl-3"
          >
            {{ error.$message }}
          </small>
        </div>
        <input
          v-model="formData.subject"
          class="w-full md:w-[99%] my-10 py-3 px-4 outline-none"
          :class="getClass(v$.formData.subject.$error)"
          type="text"
          :placeholder="
            v$.formData.subject.$error
              ? v$.formData.subject.$errors[0].$message
              : 'Your Subject*'
          "
        >
        <textarea
          v-model="formData.message"
          class="w-full md:w-[99%] h-[100px] mb-10 py-3 px-4 outline-none"
          :class="getClass(v$.formData.message.$error)"
          type="text"
          :placeholder="
            v$.formData.message.$error
              ? v$.formData.message.$errors[0].$message
              : 'Enter message*'
          "
        />

        <div v-if="loading">
          <Loader />
        </div>

        <button
          v-if="loading == false"
          class="bg-secondary hover:bg-[#f05959] text-xl text-white px-8 py-2 flex place-items-center font-compiler-500"
        >
          SEND
          <svg
            class="fill-[#ffffff] w-[15px] ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
            />
          </svg>
        </button>
      </form>
    </div>
  </appSection>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import Loader from "@/components/Loader/Loader.vue";

export default {
  name: "ContactMe",
  components: { Loader },
  data () {
    return {
      v$: useValidate(),
      loading: false,
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      isError: false,
      errorMsg: "",
    };
  },

  computed: {
    getClass () {
      return (error) => {
        return {
          "border-secondary": error,
          "placeholder-secondary": error,
        };
      };
    },
  },
  validations () {
    return {
      formData: {
        name: { required },
        email: { required, email },
        subject: { required },
        message: { required },
      },
    };
  },
  methods: {
    async sendMail () {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      try {
        this.loading = true;
        let contactUsResponse = await this.$store.dispatch(
          "serviceContact",
          this.formData,
        );
        if (
          contactUsResponse.status === 200 &&
          contactUsResponse.data.code === 200
        ) {
          this.isError = false;
          this.loading = false;
          this.showToast("Success", "Email sent successfully!");
          this.$router.push({ name: "home" });
          return;
        } else if (contactUsResponse.data.code === 400) {
          this.isError = true;
          this.errorMsg = contactUsResponse.data.message;
          this.loading = false;
        } else if (contactUsResponse.status === 500) {
          this.isError = true;
          this.errorMsg = "Internal Server Error";
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
        this.showToast("Failed", "Something went wrong!");
      }
    },
    showToast (status, msg) {
      const color = status === "Success" ? "green" : "red";
      createToast(
        {
          title: status,
          description: msg,
        },
        {
          timeout: 5000,
          toastBackgroundColor: color,
          hideProgressBar: true,
          position: "bottom-right",
        },
      );
    },
  },
};
</script>
