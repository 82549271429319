<template>
  <app-layout :breadcrumb="breadcrumb"
              heading="Cart"
  >
    <div
      v-if="products.length > 0"
      class="mx-auto w-[95%] md:w-[80%] xl:w-full flex justify-between parent flex-col items-center lg:items-start lg:flex-row my-12 text-black"
    >
      <div class="text-black w-full px-5">
        <div
          class="uppercase hidden lg:flex justify-between font-medium bg-gray-100 py-4"
        >
          <div class="w-[40%] flex justify-center">
            <span> PRODUCT</span>
          </div>
          <div class="w-[13%]">
            Price
          </div>
          <div class="w-[21%]">
            Quantity
          </div>
          <div class="w-[19%]">
            Subtotal
          </div>
        </div>

        <div
          v-for="(product, index) in products"
          :key="index"
          class="border-b-[2px] border-gray-300 last:border-b-0 flex justify-between my-8 py-4"
        >
          <div class="w-1/5 flex">
            <img
              :src="require(`@/assets/Image/ShopImg/${product.imgUrl}`)"
              class="h-[70px] object-contain"
            >
          </div>
          <div
            class="flex flex-col lg:flex-row lg:justify-between w-4/5 text-lg lg:text-base"
          >
            <div class="flex justify-between w-full lg:w-[25%]">
              <span class="text-black text-sm sm:text-base mr-12 lg:mr-0">{{
                product.name
              }}</span>
              <img
                class="w-[20px] h-[20px] delete-icon lg:hidden cursor-pointer hover:scale-125"
                src="../../assets/Image/ShopImg/delete-icon.png"
                @click="delteItem(product)"
              >
            </div>
            <div class="w-full flex justify-between lg:w-[10%] my-6 lg:my-0">
              <span class="lg:hidden text-sm sm:text-base">Price</span>
              <span> ${{ product.price }}</span>
            </div>
            <div
              class="w-full xl:w-[20%] lg:w-[24%] flex justify-between lg:block"
            >
              <span class="lg:hidden text-sm sm:text-base">Quantity</span>
              <div class="flex">
                <button
                  class="py-1 px-4 bg-gray-200 hover:bg-gray-400 transition-all duration-400 ease-in-out font-bold"
                  @click="handelClick('decrement', product)"
                >
                  -
                </button>
                <span class="px-4 py-1">{{ product.quantity }}</span>
                <button
                  class="py-1 px-4 bg-gray-200 hover:bg-gray-400 transition-all duration-400 ease-in-out font-bold"
                  @click="handelClick('increment', product)"
                >
                  +
                </button>
              </div>
            </div>
            <div class="w-full lg:w-[22%] flex justify-between my-6 lg:my-0">
              <span class="lg:hidden text-sm sm:text-base">Subtotal</span>
              <span>
                ${{ (product.price * product.quantity).toFixed(2) }}
              </span>
              <img
                class="w-[20px] h-[20px] delete-icon cursor-pointer hidden lg:block hover:scale-125"
                src="../../assets/Image/ShopImg/delete-icon.png"
                @click="delteItem(product)"
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full lg:w-1/3 checkout-card bg-gray-100 md:h-[50%] p-5 md:p-10"
      >
        <div class="mt-3">
          <span class="text-xl md:text-2xl font-medium uppercase">Cart Totals</span>
        </div>
        <div
          class="w-full flex justify-between md:text-xl my-10 pb-2 border-[#dddddd] border-b"
        >
          <span class="font-medium">Subtotal</span>
          <span> ${{ cartItemsTotal }}</span>
        </div>
        <div class="w-full flex justify-between md:text-xl">
          <span class="font-medium">Total</span>
          <span> ${{ cartItemsTotal }}</span>
        </div>
        <div
          class="w-full flex justify-center text-white text-xl place-content-end mt-8"
        >
          <button
            class="bg-secondary cursor-pointer hover:bg-red-600 text-base px-6 py-2 rounded-md flex-grow"
            @click="$router.push({ name: 'Checkout' })"
          >
            Proceed to Checkout
          </button>
        </div>
      </div>
    </div>

    <div
      v-else
      class="w-full justify-center items-center my-24 text-black flex flex-col"
    >
      <img
        class="w-[40%] h-[50vh] block"
        src="../../assets/Image/ShopImg/empty-cart.jpg"
      >
      <router-link to="/shop">
        <span class="underline text-secondary ml-4">Checkout our latest Products</span>
      </router-link>
    </div>
  </app-layout>
</template>

<script>
export default {
  data () {
    return {
      breadcrumb: ["Home", "Product", "Shop", "Cart"],
      totalPrice: 0,
      newProducts: [],
    };
  },
  computed: {
    totalSum () {
      return this.newProducts
        .reduce((accumulator, products) => {
          return accumulator + products.price * products.quantity;
        }, 0)
        .toFixed(2);
    },
    products () {
      return this.$store.getters.cartItems;
    },
    cartItemsTotal () {
      return this.$store.getters.cartItemsTotalPrice;
    },
  },
  mounted () {
    this.newProducts = JSON.parse(localStorage.getItem("cart"));
  },
  methods: {
    delteItem (item) {
      this.$store.dispatch("delteItem", item);
    },
    handelClick (type, item) {
      if (type === "decrement" && item.quantity > 1) {
        this.$store.dispatch("decrementQuantity", item);
      } else if (type === "increment") {
        this.$store.dispatch("incrementQuantity", item);
      }
    },
  },
};
</script>
