<template>
  <app-layout :breadcrumb="breadcrumb"
              heading="Failed"
  >
    >
    <div class="rounded-3xl w-[100%] py-20 flex justify-center">
      <div
        class="w-[90%] md:w-[45%] shadow-2xl py-8 flex justify-center flex-col items-center"
      >
        <div
          class="gradient w-[50px] h-[50px] rounded-full flex flex-col justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="w-[20px] fill-[#ffff]"
          >
            <path
              d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM99.5 144.8C77.15 176.1 64 214.5 64 256C64 362 149.1 448 256 448C297.5 448 335.9 434.9 367.2 412.5L99.5 144.8zM448 256C448 149.1 362 64 256 64C214.5 64 176.1 77.15 144.8 99.5L412.5 367.2C434.9 335.9 448 297.5 448 256V256z"
            />
          </svg>
        </div>
        <span class="text-2xl text-red-300 font-bold my-5">Payment Failed!</span>

        <button
          class="gradient bg-red-500 py-2 px-4 rounded-xl"
          @click="backTo"
        >
          Try Again
        </button>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/layout/AppLayout.vue";
export default {
  name: "CancelURL.vue",

  components: { AppLayout },
  data () {
    return {
      breadcrumb: ["Home", "cancelPaymet"],
    };
  },
  methods: {
    backTo () {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped>
.gradient {
  background: #ff416c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff4b2b,
    #ff416c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff4b2b,
    #ff416c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
