<template>
  <div
    class="w-11/12 md:w-1/3 xl:w-1/5 bg-[#e9e9e9] mx-auto mt-5 md:mt-0 md:h-[100vh]"
  >
    <div class="md:my-10 flex flex-col w-full my-4">
      <div
        class="pl-4 md:mx-6 mb-4 md:my-6 flex  py-2 rounded-l-lg cursor-pointer"
        :class="{ active: isActive === 'myservices' }"
        @click="$router.push({ name: 'MyServices' })"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 496 512"
          class="mr-4 h-[20px]"
        >
          <g>
            <g>
              <g>
                <path
                  d="M482.344,264.968C491.024,256.288,496,244.272,496,232c0-12.272-4.976-24.288-13.656-32.968L416,132.688L340.688,208
				l32,32L240,372.688l-32-32L132.688,416l66.344,66.344C207.712,491.024,219.728,496,232,496c12.272,0,24.288-4.976,32.968-13.656
				l18.456-18.456C306.824,484.576,336.504,496,368,496c70.576,0,128-57.424,128-128c0-31.504-11.424-61.192-32.112-84.576
				L482.344,264.968z M363.312,208L416,155.312L428.688,168L376,220.688L363.312,208z M155.312,416L208,363.312L220.688,376
				L168,428.688L155.312,416z M271.832,452.848l-18.176,18.184C247.952,476.736,240.064,480,232,480
				c-8.064,0-15.952-3.264-21.656-8.968L179.312,440L232,387.312l8,8L395.312,240l-8-8L440,179.312l31.032,31.032
				C476.736,216.048,480,223.936,480,232c0,8.064-3.264,15.952-8.968,21.656l-18.176,18.176L271.832,452.848z M480,368
				c0,61.76-50.24,112-112,112c-27.232,0-52.984-9.656-73.344-27.344l158-158C470.344,315.008,480,340.76,480,368z"
                />
                <path
                  d="M208,336v-29.792c10.232-2.96,20.096-7.048,29.44-12.208l21.072,21.08l56.568-56.568L294,237.44
				c5.168-9.344,9.248-19.2,12.208-29.44H336v-80h-29.792c-2.96-10.232-7.048-20.096-12.208-29.44l21.08-21.072L258.512,20.92
				L237.44,42c-9.344-5.168-19.2-9.248-29.44-12.208V0h-80v29.792C117.768,32.752,107.904,36.84,98.56,42L77.488,20.92L20.92,77.488
				L42,98.56c-5.168,9.344-9.248,19.2-12.208,29.44H0v80h29.792c2.96,10.232,7.048,20.096,12.208,29.44l-21.08,21.072l56.568,56.568
				L98.56,294c9.344,5.168,19.2,9.248,29.44,12.208V336H208z M101.432,277.168l-5.376-3.28l-18.56,18.568l-33.944-33.944
				l18.568-18.56l-3.28-5.376c-6.92-11.328-12.008-23.592-15.12-36.448L42.24,192H16v-48h26.24l1.48-6.128
				c3.112-12.848,8.192-25.12,15.12-36.448l3.28-5.376l-18.568-18.56l33.944-33.944l18.56,18.568l5.376-3.28
				c11.328-6.92,23.592-12.008,36.448-15.12L144,42.24V16h48v26.24l6.128,1.48c12.848,3.112,25.12,8.192,36.448,15.12l5.376,3.28
				l18.56-18.568l33.944,33.944l-18.568,18.56l3.28,5.376c6.92,11.328,12.008,23.592,15.12,36.448l1.472,6.12H320v48h-26.24
				l-1.48,6.128c-3.112,12.848-8.192,25.12-15.12,36.448l-3.28,5.376l18.568,18.56l-33.944,33.944l-18.56-18.568l-5.376,3.28
				c-11.328,6.92-23.592,12.008-36.448,15.12L192,293.76V320h-48v-26.24l-6.128-1.48
				C125.024,289.168,112.76,284.088,101.432,277.168z"
                />
                <path
                  d="M256,168c0-48.52-39.48-88-88-88s-88,39.48-88,88s39.48,88,88,88S256,216.52,256,168z M96,168c0-39.704,32.296-72,72-72
				s72,32.296,72,72s-32.296,72-72,72S96,207.704,96,168z"
                />

                <rect
                  x="231.995"
                  y="324.69"
                  transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 647.2864 403.8884)"
                  width="16"
                  height="22.624"
                />

                <rect
                  x="324.683"
                  y="231.998"
                  transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 403.8752 647.2874)"
                  width="22.624"
                  height="16"
                />
              </g>
            </g>
          </g>
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
        </svg>

        <span class="cursor-pointer text-black">My Services</span>
      </div>
      <div
        class="pl-4 md:mx-6 flex py-2 rounded-l-lg cursor-pointer"
        :class="{ active: isActive === 'profile' }"
        @click="$router.push({ name: 'Profile' })"
      >
        <svg class="mr-4 h-[20px]"
             viewBox="0 0 59.985 59.985"
        >
          <path
            d="M59.985,7c0-1.87-0.728-3.627-2.05-4.949S54.855,0,52.985,0s-3.627,0.729-4.95,2.051l-1.414,1.414l-4.243,4.242l0,0
	L4.536,45.551c-0.11,0.109-0.192,0.243-0.242,0.391L0.051,58.669c-0.12,0.359-0.026,0.756,0.242,1.023
	c0.19,0.19,0.446,0.293,0.707,0.293c0.106,0,0.212-0.017,0.316-0.052l12.728-4.243c0.147-0.049,0.281-0.132,0.391-0.241
	l37.843-37.843l0,0l4.242-4.242l0,0l1.415-1.415C59.257,10.627,59.985,8.87,59.985,7z M52.278,14.778l-7.071-7.071l1.414-1.414
	l7.071,7.071L52.278,14.778z M5.68,48.109l6.197,6.196l-9.296,3.099L5.68,48.109z M13.728,53.328l-7.071-7.07L43.793,9.121
	l7.071,7.071L13.728,53.328z M55.106,11.95l-7.071-7.071l1.414-1.414C50.394,2.521,51.65,2,52.985,2s2.591,0.521,3.536,1.465
	s1.464,2.2,1.464,3.535s-0.52,2.591-1.464,3.535L55.106,11.95z"
          />
        </svg>

        <span class="cursor-pointer text-black">Edit profile</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.active {
  color: black;
  background-color: #cacaca;
}
</style>
