<template>
  <app-layout :breadcrumb="breadcrumb"
              heading="Privacy Policy"
  >
    <div class="text-black my-16 shadow-xl box-border p-16">
      <span class="font-bold text-4xl">Privacy Policy for Alessandro Cavagnola</span>

      <p class="my-6">
        At Alessandro Cavagnola, accessible from
        <a
          target="_blank"
          class="text-secondary font-semibold"
          href="https://alessandrocavagnola.com/"
        >
          https://alessandrocavagnola.com/</a>
        one of our main priorities is the privacy of our visitors. This Privacy
        Policy document contains types of information that is collected and
        recorded by Alessandro Cavagnola and how we use it.
      </p>

      <p class="my-6">
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>

      <p class="my-4">
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in Alessandro Cavagnola. This policy is not
        applicable to any information collected offline or via channels other
        than this website. Our Privacy Policy was created with the help of the
        <a href="https://www.generateprivacypolicy.com/">Free Privacy Policy Generator</a>.
      </p>

      <span class="text-3xl font-bold my-6 block">Consent</span>

      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>

      <span class="font-semibold text-xl my-4 block">Information we collect</span>

      <p class="my-4">
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </p>
      <p class="my-4">
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
      </p>
      <p class="my-4">
        When you register for an Account, we may ask for your contact
        information, including items such as name, company name, address, email
        address, and telephone number.
      </p>

      <span class="text-xl font-medium">How we use your information</span>

      <p class="my-4">
        We use the information we collect in various ways, including to:
      </p>

      <ul class="ml-6 list-disc">
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>

      <span class="my-6 block font-semibold text-xl">Cookies and Web Beacons</span>

      <p>
        Like any other website, Alessandro Cavagnola uses 'cookies'. These
        cookies are used to store information including visitors' preferences,
        and the pages on the website that the visitor accessed or visited. The
        information is used to optimize the users' experience by customizing our
        web page content based on visitors' browser type and/or other
        information.
      </p>
      <span class="font-semibold text-xl block my-6">Third Party Privacy Policies</span>

      <p>
        Alessandro Cavagnola's Privacy Policy does not apply to other
        advertisers or websites. Thus, we are advising you to consult the
        respective Privacy Policies of these third-party ad servers for more
        detailed information. It may include their practices and instructions
        about how to opt-out of certain options.
      </p>

      <p class="my-4">
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites.
      </p>
    </div>
  </app-layout>
</template>

<script>
export default {
  data () {
    return {
      breadcrumb: ["Home", " Privacy Policy"],
    };
  },
};
</script>

<style scoped></style>
