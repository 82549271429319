<template>
  <section
    id="testimonial"
    class="testimonials-section text-white h-[100vh] bg-cover bg-fixed bg-center mx-auto relative top-0 left-0 flex justify-center md:w-full"
  >
    <div
      class="gradient-container bg-gradient-to-l from-black to-[#f4373830] flex justify-center items-center w-[100%] h-[100%]"
    >
      <div class="absolute w-[100%] h-[50%] 2xl:h-[40%]">
        <div class="mx-auto w-[80%] h-full flex justify-end">
          <div class="h-[100%] lg:h-[90%] w-full md:w-1/2 lg:w-1/2">
            <swiper
              :space-between="30"
              :pagination="{
                clickable: true,
                bulletClass: `swiper-pagination-bullet`,
                currentClass: `current-bullets`,
              }"
              :modules="modules"
              class="mySwiper h-full w-full cursor-pointer"
            >
              <swiper-slide
                v-for="(testimonial, index) in testimonialsData"
                :key="index"
              >
                <div
                  class="content w-full h-full flex justify-center items-center flex-col"
                >
                  <p id="name"
                     class="mt-3 text-2xl font-bold"
                  >
                    {{ testimonial.name }}
                  </p>
                  <p class="text-center font-light mt-6 text-xl">
                    {{ testimonial.review }}
                  </p>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
export default {
  name: "TestimonialsSection",
  components: {
    Swiper,
    SwiperSlide,
  },

  setup () {
    return {
      modules: [Pagination],
    };
  },

  data () {
    return {
      testimonialsData: [
        {
          name: "Eleonora Montorfano",

          review:
            "Super available coach for any information or clarification of nutrition and training protocols. She checks every 7 days, but despite this she supports and motivates you every day. I tried to train with him, and I must say that he isreally very technical, precise in the execution of each exercise.",
        },
        {
          name: " Giacomo Becchetti",
          review:
            "I started being followed by Alessandro 4 and a half months ago. I had a body that I absolutely did not like, with him I really understand what it means to go to the gym and he has also changed me mentally. I found myself and I have a great time because he is super helpful, kind, motivating, in short, he is really a coach who reflects all my requests.",
        },
        {
          name: "Mattia di crescenzo",

          review:
            "  It's only been two months since I started the journey, but I already see improvements.  I am not one who asks a lot of questions but when I have some doubts he is always available.  Excellent cards that make me even more the stimulus to train and excellent nutritional advice.  Thanks bro!",
        },
        {
          name: "  Michele Simone",
          review:
            "Alessandro is a coach who is humanly close to the client's needs, nothing for granted.  Punctual and professional, prepared and practical.  The results with the right commitment reach 100%.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.testimonials-section {
  background-image: url("@/assets/Image/HomeImg/Testimonial-backgorund.jpg");
}
#name {
  text-shadow: 1px 2px black;
}
</style>
