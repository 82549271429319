import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
export function showToast (type, msg,position) {

  if(position==undefined){
    position="bottom-right";
  }
  else{
    position = "top-center";
  }
  createToast(
    {
      description: msg,
    },
    {
      position: position,
      timeout: 2000,
      type: type,
      hideProgressBar: false,
    },
  );
}

export function isNumeric (password) {
  let numbers = /^[0-9]+$/;
  let result = password.match(numbers);
  if (result !== null) {
    this.isError = true;
    this.errorMsg = "password can not be entirely numeric";
    return true;
  } else {
    return false;
  }
}

export function getClass () {
  return (error) => {
    return {
      "border-secondary": error,
      "placeholder-secondary": error,
    };
  };
}

export function togglePasswordVisibility () {
  if (
    this.eyeImag == "hide-password.png" &&
    this.passwordInputType == "password"
  ) {
    this.eyeImag = "visible-password.png";
    this.passwordInputType = "text";
  } else {
    this.eyeImag = "hide-password.png";
    this.passwordInputType = "password";
  }
}
