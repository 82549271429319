<template>
  <app-layout heading="Service"
              :breadcrumb="breadcrumb"
  >
    <section class="py-10">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap -mx-4 mb-16">
          <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <div
              v-show="showImage"
              class="relative mb-10 w-full md:h-5/6 flex justify-start md:w-4/6"
            >
              <img
                class="w-full md:h-full object-cover object-top"
                :src="image"
                alt="../../assets/Image/ShopImg/white-imag.jpg"
              >
            </div>
          </div>

          <div class="w-full md:w-1/2 px-4">
            <div class="lg:pl-20">
              <div class="mb-10 pb-10 border-b">
                <h2
                  class="mt-2 mb-3 max-w-xl text-3xl md:text-4xl font-bold font-heading text-gray-600"
                >
                  {{ service.name }}
                </h2>
                <div class="mb-4 text-gray-300 flex items-end">
                  <rating :width="service.rating * 20" />
                </div>
                <div
                  class="text-xl flex flex-col font-heading text-gray-800"
                  style="margin-bottom: 1.5rem"
                >
                  <span
                    :class="{
                      'text-red-800 line-through': service.offer_price,
                    }"
                    class="mr-2 block"
                  >
                    ${{ service.onetime_price }}
                  </span>
                  <span
                    v-show="service.offer_price"
                    class="mr-2 mt-3 block font-bold"
                  >
                    ${{ service.offer_price }}
                  </span>
                </div>
                <div
                  class="text-xl flex font-heading text-gray-800"
                  style="margin-bottom: 1.5rem"
                >
                  <p class="mr-2">
                    {{ service.duration }}
                  </p>
                </div>

                <p class="max-w-md text-gray-500">
                  {{ service.short_details }}
                </p>
              </div>

              <div class="flex mb-12">
                <div class="mr-6"
                     :class="{ hidden: hide }"
                >
                  <span
                    class="block mb-4 font-bold font-heading text-gray-400 uppercase"
                  >No of Calendly session you want to book</span>
                  <div
                    class="inline-flex items-center px-4 font-semibold font-heading text-gray-500 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                  >
                    <button
                      class="py-2 hover:text-gray-700"
                      @click="handelCount('decrement')"
                    >
                      <svg
                        width="12"
                        height="2"
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.35">
                          <rect
                            x="12"
                            width="2"
                            height="12"
                            transform="rotate(90 12 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </button>
                    <input
                      v-model="quantity"
                      class="w-12 m-0 px-2 py-4 text-center md:text-right border-0 focus:ring-transparent focus:outline-none rounded-md text-black"
                      type="number"
                      min="1"
                    >
                    <button
                      class="py-2 hover:text-gray-700"
                      @click="handelCount('increment')"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.35">
                          <rect
                            x="5"
                            width="2"
                            height="12"
                            fill="currentColor"
                          />
                          <rect
                            x="12"
                            y="5"
                            width="2"
                            height="12"
                            transform="rotate(90 12 5)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="text-black"
                   :class="{ hidden: slotDivHidden }"
              >
                <span
                  class="block mb-6 font-bold font-heading text-gray-400 uppercase"
                >No of sessions Available: {{ slot }}</span>
              </div>
              <div class="flex flex-wrap -mx-4 mb-14 items-center">
                <div class="w-full xl:w-1/2 px-4 mb-4 xl:mb-0">
                  <button
                    class="block bg-secondary w-full hover:bg-red-700 text-center text-white font-bold font-heading py-5 px-8 rounded-md uppercase transition duration-200"
                    :class="{ hidden: hide }"
                    @click="buyNow"
                  >
                    Buy Now
                  </button>
                </div>
                <div
                  ref="calandlymodal"
                  :class="{ fullWidth: hide }"
                  class="w-full xl:w-2/4 px-4 mb-4 xl:mb-0"
                >
                  <button
                    :disabled="!hide"
                    class="tooltip block bg-primary w-full hover:bg-primary-700 text-center text-white font-bold font-heading py-5 px-8 rounded-md uppercase transition duration-200"
                    style="cursor: pointer"
                    @click="goToCalendlyURL(id, names)"
                  >
                    <span :class="{ hidden: hide }"
                          class="tooltiptext"
                    >Please Buy the Service First!</span>
                    Book Appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mb-10"
          >
            <ul class="flex flex-wrap -mb-px">
              <div
                class="inline-block rounded-t-lg border-b-2 border-transparent cursor-pointer"
              >
                <h3 class="mb-8 text-3xl font-bold font-heading text-gray-600">
                  Description
                </h3>
              </div>
            </ul>
          </div>
          <div>
            <p class="max-w-3xl text-gray-500">
              {{ service.details }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <Spinner v-if="isLoading" />
  </app-layout>
</template>

<script>
import Rating from "../Shop/Rating.vue";
import axios from "axios";
import { showToast } from "../Utility/utils";
export default {
  components: {
    Rating,
  },
  async beforeRouteUpdate (to, from, next) {
    let individualServices = await this.$store.dispatch(
      "service",
      to.params.id,
    );
    this.service = individualServices.data;
    this.image = individualServices.data.images[0];
    this.names = individualServices.data.name;
    this.showImage = true;
    this.id = individualServices.data.id;
    this.breadcrumb.pop();
    this.breadcrumb.push(this.names);
    next();
  },
  data () {
    return {
      service: "",
      quantity: 1,
      names: "",
      isLoading: false,
      slotDivHidden: true,
      showImage: false,
      breadcrumb: ["Home", "Service"],
      image: "",
      id: "",
      placeholder: "Hello",
      slot: "",
      bookService: "",
      hide: true,
      userProfile: "",
    };
  },
  computed: {
    serviceSlug () {
      return (name) => {
        return name
          .toLowerCase()
          .trim()
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-");
      };
    },
  },
  async created () {
    this.isLoading = true;
    let individualServices = await this.$store.dispatch(
      "service",
      this.$route.params.id,
    );
    this.service = individualServices.data;
    this.image = individualServices.data.images[0];
    this.names = individualServices.data.name;
    this.showImage = true;
    this.id = individualServices.data.id;
    this.breadcrumb.push(this.names);
    if (this.$store.getters.isLogin) {
      let response = await axios.get(
        `https://alessandrocavagnola.com/api/service/booking?service_id=${this.service.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          },
        },
      );

      if (response.status === 200) {
        this.bookService = response.data;
        if (this.bookService.service.length > 0) {
          this.slot = this.bookService.service[0].slots;
          if (this.slot < 1) {
            this.hide = false;
          }
          if (this.slot > 0) {
            this.slotDivHidden = false;
          }
        } else {
          this.hide = false;
        }
      }
    } else {
      this.hide = false;
    }
    this.isLoading = false;
  },
  methods: {
    async goToCalendlyURL (id, url) {
      if (!this.$store.getters.isLogin) {
        this.$router.push({ name: "Signin" });
      }
      if (this.$store.getters.isLogin) {
        let profile_attrs = this.$store.getters.profile;
        var userName = "";
        var userEmail = "";
        if (this.$store.getters.profile.first_name !== undefined) {
          userName = `${profile_attrs.first_name} ${profile_attrs.last_name}`;
          userEmail = profile_attrs.email;
        } else {
          let response = await axios.get("/auth/profile", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            },
          });
          if (response.status === 200) {
            userName = `${response.data.accounts.first_name} ${response.data.accounts.last_name}`;
            userEmail = response.data.accounts.email;
          }
        }
        let username = userName;
        let useremail = userEmail;
        let receipt_url = this.bookService.service[0].receipt_url;
        let sluggedURL = this.serviceSlug(url);
        sluggedURL =
          sluggedURL +
          `?hide_landing_page_details=1&email=${useremail}&name=${username}&a1=${receipt_url}`;
        this.$router.push({ name: "Calendly", params: { id, sluggedURL } });
      }
    },
    handelCount (type) {
      if (type === "increment" && this.quantity > 0) {
        this.quantity++;
      } else if (type === "decrement" && this.quantity > 1) {
        this.quantity--;
      }
    },
    async buyNow () {
      if (!this.$store.getters.isLogin) {
        this.$router.push({ name: "Signin" });
        showToast("info", "Please Signin first before buying services");
        return;
      }
      try {
        let response1 = await axios.get("/auth/profile", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          },
        });
        let amount;
        if (this.service.offer_price) {
          amount = Math.round(this.service.offer_price * 100);
        } else {
          amount = Math.round(this.service.onetime_price * 100);
        }

        let data = {
          currency: "usd",
          name: this.service.name,
          unit_amount: amount,
          quantity: this.quantity,
          success_url: `https://alessandrocavagnola.com/success/${this.service.id}`,
          cancel_url: `https://alessandrocavagnola.com/service/${this.service.id}`,
          service_id: this.service.id,
          email: response1.data.accounts.email,
        };
        this.isLoading = true;
        let response = await axios.post(
          "https://alessandrocavagnola.com/api/service/stripe-checkout",
          data,
        );
        window.location.href = response.data.service.session_url;
        this.isLoading = false;
        return response;
      } catch (error) {
        return error.response;
      }
    },
  },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #20232a;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  top: -70px;
  /* left: 105%; */
}
.fullWidth {
  width: 100%;
}
</style>
