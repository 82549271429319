function upateLocalStorage (cart) {
  localStorage.setItem("cart", JSON.stringify(cart));
}

function productSlug (name) {
  return name.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

const state = {
  products: [{
    name: "The Flexibell",
    category: "Gym Equipment",
    price: "7.49",
    imgUrl: "Product-1.png",
    rating: "4",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "MuscleBlaze Whey Gold 100% Whey Protein Isolate",
    category: "Protein",
    price: "19.9",
    imgUrl: "Product-3.png",
    rating: "4.6",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This is addtional info of  this product",
  },
  {
    name: "Big Muscles Nutrition Premium Gold Whey 1Kg",
    category: "Supplements",
    price: "30",
    offerPrice: "23.3",
    imgUrl: "Product-6.jpg",
    rating: "4.9",
    description:
      "This is product is very good for health.   Lorem ipsum dolo ist amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "HealthXP Shield Whey 2.2 lbs, 1 kg, With Hydrolysed Whey Protein Isolate",
    category: "Supplements",
    price: "9.9",
    imgUrl: "Product-7.jpg",
    rating: "4.2",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "Ultimate Nutrition Prostar 100% Whey Protein 5.28 lbs, 2.39 kg",
    category: "Supplements",
    price: "21.73",
    imgUrl: "Product-8.jpg",
    rating: "3.4",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "Single Toning Resistance Tube Pull Rope Exercise Band for Stretching",
    category: "Gym Equipment",
    price: "4.00",
    imgUrl: "Product-10.jpg",
    rating: "2",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "MuscleBlaze Whey Gold 100% Whey Protein Isolate",
    category: "Protein",
    price: "45.00",
    imgUrl: "Product-3.png",
    rating: "4",
    sales: false,
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "Optimum Nutrition Gold Standard 100% Whey Protein 2 lb Double Rich Chocolate",
    category: "Protein",
    price: "16.9",
    imgUrl: "Product-1.png",
    rating: "1",
    offPrice: "11.99",
    sales: true,
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "Optimum Nutrition Gold Standard 100% Whey Protein 2 lb Double Rich Chocolate",
    category: "Protein",
    price: "17.4",
    imgUrl: "Product-2.png",
    rating: "4",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "Optimum Nutrition Gold Standard 100% Whey Protein 2 lb Double Rich Chocolate",
    category: "Protein",
    price: "9.9",
    imgUrl: "Product-1.png",
    rating: "4.2",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "Optimum Nutrition Gold Standard 100% Whey Protein 2 lb Double Rich Chocolate",
    category: "Protein",
    price: "5.74",
    imgUrl: "Product-1.png",
    rating: "1",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "Optimum Nutrition Gold Standard 100% Whey Protein 2 lb Double Rich Chocolate",
    category: "Protein",
    price: "7.49",
    imgUrl: "Product-2.png",
    rating: "4",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "MuscleBlaze Whey Gold 100% Whey Protein Isolate",
    category: "Protein",
    price: "45.00",
    imgUrl: "Product-3.png",
    rating: "2",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "MuscleBlaze Whey Gold 100% Whey Protein Isolate",
    category: "Protein",
    price: "45.00",
    imgUrl: "Product-3.png",
    rating: "4",
    sales: false,
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "Optimum Nutrition Gold Standard 100% Whey Protein 2 lb Double Rich Chocolate",
    category: "Protein",
    price: "16.9",
    imgUrl: "Product-1.png",
    rating: "1",
    offPrice: "11.99",
    sales: true,
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  {
    name: "Optimum Nutrition Gold Standard 100% Whey Protein 2 lb Double Rich Chocolate",
    category: "Protein",
    price: "17.4",
    imgUrl: "Product-2.png",
    rating: "4",
    description:
      "This is product is very good for health.   Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae similique maiores officiis error repellat dolores impedit fuga dicta modi esse aliquam soluta nesciunt commodi, facere asperiores corrupti reprehenderit. Velit voluptate asperiores, dolor illum labore dolorum nemo exercitationem natus quaerat porro.",
    additionalInfo: "This si addtional info of  this product",
  },
  ],
  cart: [],
};

const getters = {
  cartItems (state) {
    return state.cart;
  },
  products (state) {
    return state.products;
  },
  cartTotal (state) {
    return state.cart.reduce((a, b) => a + b.quantity, 0);
  },
  cartItemsTotalPrice (state) {
    return state.cart.reduce((a, b) => a + b.price * b.quantity, 0).toFixed(2);
  },
  productByCategory (state) {
    return (product) => {
      let item = state.products.filter((i) => i.category == product.category);
      item = item.filter((i) => i.name !== product.name);
      return item.slice(0, 3);
    };
  },
  getProductsByID (state) {
    return (slug) => {
      return state.products.find(el => productSlug(el.name) === slug);
    };

  },
};

const mutations = {
  addToCartByQuantity (state, payload) {
    const { product, quantity } = payload;
    const item = state.cart.find((i) => i.name === product.name);
    if (item) {
      item.quantity = quantity;
    } else {
      state.cart.push({ ...product, quantity: quantity });
    }
    upateLocalStorage(state.cart);
  },
  incrementQuantity  (state, product) {
    const item = state.cart.find((i) => i.name === product.name);
    if (item) {
      item.quantity++;
    }
    upateLocalStorage(state.cart);
  },
  decrementQuantity (state, product) {
    let item = state.cart.find((i) => i.name === product.name);
    if (item) {
      if (item.quantity > 1) {
        item.quantity--;
      }
    }
    upateLocalStorage(state.cart);
  },
  // cleaning up cart after succesfull checkout
  emptyCart (state) {
    state.cart = [];
    upateLocalStorage(state.cart);
  },
  // delete item(product) from cart page on single click
  delteItem (state, product) {
    state.cart = state.cart.filter((i) => i.name !== product.name);
    upateLocalStorage(state.cart);
  },
  updateCartFromLocalStorage (state) {
    const cart = localStorage.getItem("cart");
    if (cart)  state.cart = JSON.parse(cart);
  },
};

const actions = {
  addToCartByQuantity: (context, payload) => {
    context.commit("addToCartByQuantity", payload);
  },
  emptyCart: (context, state) => {
    context.commit("emptyCart", state);
  },
  delteItem: (context, state) => {
    context.commit("delteItem", state);
  },
  incrementQuantity: (context, state) => {
    context.commit("incrementQuantity", state);
  },
  decrementQuantity: (context, state) => {
    context.commit("decrementQuantity", state);
  },
  updateCartFromLocalStorage: (context,state)=>{
    context.commit("updateCartFromLocalStorage",state);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
