<template>
  <div class="text-black my-16">
    <span class="block text-3xl font-bold mb-8">Releated Product</span>
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center"
    >
      <div
        v-for="(productcate, index) in productByCategory"
        :key="index"
        class="imageDIv overflow-hidden rounded-lg cursor-pointer  my-4 mr-4 shadow-md text-black w-72 mb-2 md:w-[320px] xl:w-96"
      >
        <div class="relative">
          <img
            :src="require(`@/assets/Image/HomeImg/${productcate.imgUrl}`)"
            class="max-h-72 w-full object-contain bg-white p-8 cursor-pointer"
            @click="singleProduct(productcate)"
          >
          <div class="absolute flex flex-col top-0 right-0 p-3">
            <img
              ref="heart"
              class="heart transition ease-in duration-300 bg-gray-500 shadow rounded-full w-8 h-8 text-center p-1 cursor-pointer"
              :src="require(`@/assets/Image/ShopImg/${heartImage1}`)"
              @click.prevent="handleIconClick1(index)"
            >
          </div>
        </div>
        <div class="bg-gray-100 w-full p-4 max-w-sm border-t-4">
          <div class="w-full flex-none text-sm flex items-center text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-red-500 mr-1"
              viewBox="0 0 20 20"
              fill="#f43738"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <span class="text-gray-400 whitespace-nowrap mr-3">{{
              productcate.rating
            }}</span>
          </div>
          <div class="flex items-center w-full justify-between min-w-0">
            <h2
              class="text-lg mr-auto cursor-pointer text-black hover:text-secondary truncate"
            >
              {{ productcate.name }}
            </h2>
            <div
              class="flex items-center bg-secondary text-white text-xs px-2 py-1 ml-3 rounded-lg"
            >
              INSTOCK
            </div>
          </div>
          <div class="text-xs text-gray-400 truncate mt-1">
            <a class="font-semibold hover:underline">
              {{ productcate.category }}
            </a>
          </div>

          <div class="flex flex-row mt-2">
            <div class="flex flex-auto text-gray-600 text-lg font-semibold">
              <p class="mr-2"
                 :class="isSales(productcate.offerPrice)"
              >
                ${{ productcate.price }}
              </p>
              <p v-if="productcate.offerPrice"
                 class="mr-2"
              >
                ${{ productcate.offerPrice }}
              </p>
            </div>

            <div class="flex flex-row flex-auto justify-end cursor-pointer">
              <div
                class="flex text-xs border px-3 my-auto py-2 border-black group hover:bg-black rounded-sm transition-all duration-200 hover:fill-[#fff]"
                @click="addProductQuntityOne(productcate)"
              >
                <svg
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z"
                  />
                </svg>
                <div
                  class="text-xs text-black font-semibold ml-2 group-hover:text-white delay-100"
                >
                  Add to Cart
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
export default {
  name: "ReleatedProduct",
  props: {
    productByCategory: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      heartImage: "black-heart.png",
      heartImage1: "white-heart-icon.png",
    };
  },
  computed: {
    isSales () {
      return (price) => {
        return {
          "line-through": price,
          "text-red-600": price,
        };
      };
    },
  },
  methods: {
    addProductQuntityOne (product) {
      let cartitems = this.$store.getters.cartItems;
      let itemExits = cartitems.find((i) => i.name === product.name);
      let payload = { product: product, quantity: 1 };
      if (!itemExits) {
        this.$store.commit("addToCartByQuantity", payload);
        this.showToast("success", "Product is added in a cart");
      } else {
        this.showToast("info", "Product is already exist in cart");
      }
    },
    handleIconClick1 (index) {
      this.isClicked = !this.isClicked;
      const heart = this.$refs.heart;
      const imagePath = this.isClicked
        ? "heart-red-icon.png"
        : "heart-icon.png";
      heart[index].src = require("@/assets/Image/HomeImg/" + imagePath);
    },
    singleProduct (item) {
      this.$emit("singleProduct", item);
    },
    showToast (type, msg) {
      // const color = status === "Success" ? "green" : "#f1ff62";
      createToast(
        {
          description: msg,
        },
        {
          position: "bottom-right",
          timeout: 2000,
          type: type,
          hideProgressBar: false,
        },
      );
    },
  },
};
</script>

<style scoped></style>
