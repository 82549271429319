<template>
  <app-layout heading="Shop"
              :breadcrumb="breadcrumb"
  >
    <section class="py-10">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap -mx-4 mb-16">
          <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <div class="relative mb-10"
                 style="height: 364px"
            >
              <img
                class="object-contain w-full h-full"
                :src="require(`@/assets/Image/HomeImg/${product.imgUrl}`)"
              >
            </div>
            <div class="flex flex-wrap -mx-2">
              <div
                v-for="(image, index) in images"
                :key="index"
                class="w-1/2 sm:w-1/4 p-2"
              >
                <div
                  class="block border p-4"
                  :class="[
                    product.imgUrl === image
                      ? 'border border-blue-300 shadow-lg'
                      : '',
                  ]"
                >
                  <img
                    class="w-full h-24 object-contain"
                    :src="require(`@/assets/Image/HomeImg/${image}`)"
                    @click="currentThumbnail(image)"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 px-4">
            <div class="lg:pl-20">
              <div class="mb-10 pb-10 border-b">
                <span class="text-gray-500">{{ product.category }}</span>
                <h2
                  class="mt-2 mb-3 max-w-xl text-3xl md:text-4xl font-bold font-heading text-gray-600"
                >
                  {{ product.name }}
                </h2>
                <div class="mb-4 text-gray-300 flex items-end">
                  <rating :width="product.rating * 20" />
                </div>
                <div class="mb-8 text-xl flex font-heading text-gray-800">
                  <p class="mr-2"
                     :class="isSales(product.offerPrice)"
                  >
                    ${{ product.price }}
                  </p>
                  <p v-if="product.offerPrice"
                     class="mr-2"
                  >
                    ${{ product.offerPrice }}
                  </p>
                </div>
                <p class="max-w-md text-gray-500 truncate">
                  {{ product.description }}
                </p>
              </div>
              <div class="flex mb-12">
                <div class="mr-6">
                  <span
                    class="block mb-4 font-bold font-heading text-gray-400 uppercase"
                  >QTY</span>
                  <div
                    class="inline-flex items-center px-4 font-semibold font-heading text-gray-500 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                  >
                    <button
                      class="py-2 hover:text-gray-700"
                      @click="handelCount('decrement')"
                    >
                      <svg
                        width="12"
                        height="2"
                        viewBox="0 0 12 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.35">
                          <rect
                            x="12"
                            width="2"
                            height="12"
                            transform="rotate(90 12 0)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </button>
                    <input
                      v-model="quantity"
                      class="w-12 m-0 px-2 py-4 text-center md:text-right border-0 focus:ring-transparent focus:outline-none rounded-md text-black"
                      type="number"
                      min="1"
                    >
                    <button
                      class="py-2 hover:text-gray-700"
                      @click="handelCount('increment')"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.35">
                          <rect
                            x="5"
                            width="2"
                            height="12"
                            fill="currentColor"
                          />
                          <rect
                            x="12"
                            y="5"
                            width="2"
                            height="12"
                            transform="rotate(90 12 5)"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap -mx-4 mb-14 items-center">
                <div class="w-full xl:w-2/3 px-4 mb-4 xl:mb-0">
                  <a
                    class="block bg-secondary hover:bg-red-700 text-center text-white font-bold font-heading py-5 px-8 rounded-md uppercase transition duration-200"
                    href="#"
                    @click="addToCartByQuantity"
                  >Add to cart</a>
                </div>
                <div class="w-full xl:w-1/3 px-4">
                  <div
                    class="ml-auto sm:ml-0 flex-shrink-0 inline-flex mr-4 items-center justify-center w-16 h-16 rounded-md border hover:border-gray-500"
                  >
                    <img
                      ref="heart"
                      class="heart black-heart cursor-pointer transition ease-in duration-300 rounded-full w-8 h-8 text-center p-1"
                      :src="require(`@/assets/Image/ShopImg/${heartImage}`)"
                      @click.prevent="handleIconClick"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mb-10"
          >
            <ul class="flex flex-wrap -mb-px">
              <li class="mr-2">
                <div
                  class="inline-block p-4 rounded-t-lg border-b-2 border-transparent cursor-pointer"
                  :class="getClass(1)"
                  @click="active = 1"
                >
                  Description
                </div>
              </li>
              <li class="mr-2">
                <div
                  class="inline-block p-4 rounded-t-lg border-b-2 border-transparent cursor-pointer"
                  :class="getClass(2)"
                  @click="active = 2"
                >
                  Reviews
                </div>
              </li>
            </ul>
          </div>
          <div v-if="active === 1">
            <h3 class="mb-8 text-3xl font-bold font-heading text-gray-600">
              Description
            </h3>
            <p class="max-w-3xl text-gray-500">
              {{ product.description }}
            </p>
          </div>
          <div v-if="active === 2">
            <h3 class="mb-8 text-3xl font-bold font-heading text-gray-600">
              Review
            </h3>
            <div class="max-w-3xl text-gray-500">
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Natus
                blanditiis provident nobis maxime harum, earum eius officia.
                Modi autem quos, tempora nobis eligendi id quis assumenda
                placeat hic iusto. Dolorem. Lorem ipsum, dolor sit amet
                consectetur adipisicing elit. Harum, maxime rerum? Inventore aut
                itaque, assumenda quo delectus vel voluptatem fuga ex eaque
                reiciendis optio molestias? Eius, cupiditate. Deleniti corrupti,
                voluptates recusandae dolor, rem cumque eius dolores sed,
                accusantium quisquam unde.
              </p>
            </div>
          </div>
        </div>
        <ReleatedProduct
          :product-by-category="productByCategory"
          @singleProduct="singleProduct"
        />
      </div>
    </section>
  </app-layout>
</template>

<script>
import Rating from "./Rating.vue";

import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import ReleatedProduct from "./ReleatedProduct.vue";
export default {
  components: {
    Rating,
    ReleatedProduct,
  },
  data () {
    return {
      quantity: 1,
      loading: false,
      isClicked: false,
      breadcrumb: [
        "Home",
        "Product",
        "Shop",
        "Muscleblaze Whey Gold Whey Protein",
      ],
      active: 1,
      images: ["Product-3.png", "Product-1.png", "Product-2.png"],
      product_id: this.$route.params.id,
      heartImage: "black-heart.png",
      product: false,
    };
  },
  computed: {
    getClass () {
      return (index) => {
        return this.active === index
          ? "text-blue-600 border-blue-600"
          : "hover:border-gray-300";
      };
    },
    productByCategory () {
      return this.$store.getters.productByCategory(this.product);
    },
    isSales () {
      return (price) => {
        return {
          "line-through": price,
          "text-red-600": price,
        };
      };
    },
  },
  created () {
    this.product = this.$store.getters.getProductsByID(this.product_id);
  },
  methods: {
    currentThumbnail (image) {
      this.product.imgUrl = image;
    },
    addToCartByQuantity () {
      let payload = { product: this.product, quantity: this.quantity };
      this.$store.dispatch("addToCartByQuantity", payload);
    },
    handelCount (type) {
      if (type === "increment" && this.quantity > 0) {
        this.quantity++;
      } else if (type === "decrement" && this.quantity > 1) {
        this.quantity--;
      }
    },
    singleProduct (item) {
      this.product = item;
    },
    handleIconClick1 (index) {
      this.isClicked = !this.isClicked;
      const heart = this.$refs.heart;
      const imagePath = this.isClicked
        ? "heart-red-icon.png"
        : "heart-icon.png";
      heart[index].src = require("@/assets/Image/HomeImg/" + imagePath);
    },
    addProductQuntityOne (product) {
      let cartitems = this.$store.getters.cartItems;
      let itemExits = cartitems.find((i) => i.name === product.name);
      let payload = { product: product, quantity: 1 };
      if (!itemExits) {
        this.$store.dispatch("addToCartByQuantity", payload);
        this.showToast("success", "Product is added in a cart");
      } else {
        this.showToast("info", "Product is already exist in cart");
      }
    },
    showToast (type, msg) {
      createToast(
        {
          description: msg,
        },
        {
          timeout: 2000,
          type: type,
          hideProgressBar: false,
        },
      );
    },
    handleIconClick () {
      this.isClicked = !this.isClicked;
      const heart = this.$refs.heart;
      const imagePath = this.isClicked
        ? "heart-red-icon.png"
        : "black-heart.png";
      heart.src = require("@/assets/Image/HomeImg/" + imagePath);
    },
  },
};
</script>
