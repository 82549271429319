<template>
  <div class="w-full relative">
    <app-nav background="black" />
    <div class="bg-black text-white">
      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <div class="w-[100%] h-52 flex justify-center flex-col px-6">
          <h2 class="text-5xl uppercase mt-24 text-left">
            {{ heading }}
          </h2>
          <div class="flex items-center py-4 overflow-y-auto whitespace-nowrap">
            <a href="#"
               class="text-gray-600 flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="#fafafa"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>

              <span v-for="(item, index) in breadcrumb"
                    :key="index"
              >
                <span v-if="item == 'Service'">
                  <router-link :to="{ path: '/', hash: '#service' }">
                    <span
                      class="mx-1 text-gray-200 hover:underline"
                      :class="{ 'text-secondary': isCurrentPage(index) }"
                    >
                      Service
                    </span>
                  </router-link>
                </span>
                <span v-else-if="item == 'id'">
                  <span @click="goToServiceURL(objid)">
                    <span
                      class="mx-1 text-gray-200 hover:underline"
                      :class="{ 'text-secondary': isCurrentPage(index) }"
                    >
                      {{ serviceName }}
                    </span>
                  </span>
                </span>
                <span v-else>
                  <a href="/">
                    <span
                      class="mx-1 text-gray-200 hover:underline"
                      :class="{ 'text-secondary': isCurrentPage(index) }"
                    >
                      {{ item }}
                    </span>
                  </a>
                </span>

                <span v-if="!isCurrentPage(index)"
                      class="mx-1 text-gray-200"
                >/</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
      <slot />
    </div>

    <app-footer />
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    breadcrumb: {
      type: Array,
      required: true,
    },
    objid: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      items: [
        { name: "home", url: "#home", class: "home" },
        { name: "about", url: "#about", class: "about" },
        { name: "services", url: "#service", class: "service" },
        // { name: "shop", url: "#shop", class: "shop" },
        // { name: "blog", url: "#blog", class: "blog" },
        // { name: "testimonial", url: "#testimonial", class: "testimonial" },
        { name: "social", url: "#social", class: "social" },
        { name: "Contact", url: "#contact", class: "contact" },
        // { name: "cart", path: "/cart", url: "", class: "cart", isCart: true },
      ],
      serviceName: "",
    };
  },
  computed: {
    isCurrentPage () {
      return (index) => {
        return index === this.breadcrumb.length - 1;
      };
    },
  },
  async created () {
    if (this.objid) {
      let individualServices = await this.$store.dispatch("service", this.objid);
      this.serviceName = individualServices.data.name;
    }
  },
  methods: {
    goToServiceURL (id) {
      this.$router.push({
        name: "Service",
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style></style>
