<template>
  <section
    id="home"
    class="relative flex h-screen items-center justify-center overflow-hidden bg-[#202322]"
  >
    <app-nav />
    <div
      class="bg-black/50 p-20 z-20 absolute w-full h-screen flex flex-col justify-center items-center overflow-hidden text-xl font-thin"
    >
      <img src="../../assets/Icon-Symbol/Logo-white.png"
           class="md:w-[800px]"
      >
      <button
        class="hidden bg-secondary text-white text-center mx-auto px-8 py-4 hover:bg-[#cc2222] transition duration-200 text-base font-bold uppercase"
      >
        Get Started Today
      </button>
    </div>

    <video
      autoplay
      muted
      loop
      playsinline="true"
      disablePictureInPicture="true"
      class="absolute w-full h-screen object-cover opacity-80"
    >
      <source src="@/assets/HomeMP4/video.mp4"
              type="video/mp4"
      >
    </video>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>
<style scoped>
.hero-tagline {
  word-spacing: 1rem;
}

@media (max-width: 640px) {
  .hero-tagline {
    word-spacing: 0.3rem;
  }
}
</style>
