<template>
  <app-layout :breadcrumb="breadcrumb"
              heading="order Success"
  >
    <div class="h-[100vh] flex justify-center items-center text-black flex-col">
      <img
        class="w-[20vw] h-[40vh] mb-10"
        src="../../assets/Image/ShopImg/success-order-image.png"
        alt=""
      >
      <div>
        <span>Name:-</span>
        <span>{{ FormDataDetailes.firstName }}</span>
        <span>{{ FormDataDetailes.lastName }}</span>
      </div>
      <span>Street:- {{ FormDataDetailes.streetAddress }}</span>
      <span>cityName:- {{ FormDataDetailes.cityName }}</span>
      <span>country:- {{ FormDataDetailes.country }}</span>
      <span>phone:- {{ FormDataDetailes.phone }}</span>
      <span>postCode:- {{ FormDataDetailes.postCode }}</span>
      <span>country:- {{ FormDataDetailes.country }}</span>
      <span>email:- {{ FormDataDetailes.email }}</span>
    </div>
  </app-layout>
</template>

<script>
export default {
  name: "SuccesOrder",
  data () {
    return {
      breadcrumb: [
        "Home",
        "Product",
        "Shop",
        "Cart",
        "checkout",
        "orderSuccesss",
      ],
      FormDataDetailes: "",
    };
  },
  created () {
    this.FormDataDetailes = this.$route.params;
  },
};
</script>

<style scoped>
span {
  margin: 10px;
}
</style>
