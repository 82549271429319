<template>
  <div class="absolute top-0 right-0 left-0 z-50 inset-0 img">
    <div class="p-4 z-50 fixed tw-min-h-full tw-min-w-full">
      <!-- <div
        class="relative rounded-lg h-[30vh] md:h-[50vh] flex justify-center items-center"
      > -->
      <!-- <img src="../../assets/Icon-Symbol/Symbol-logo-white.png"> -->

      <!-- </div> -->
      <div class="min-w-full">
        <img class="img"
             src="../../assets/HomeMP4/loader2.gif"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style scoped>
.img {
  height: 100vh;
  width: 100vw;
}
/* #img {
  width: 80px;
  height: 90px;
  animation: rotation 3s infinite linear;
} */

@keyframes rotation {
  to {
    transform: rotate(359deg);
  }

  from {
    transform: rotate(0deg);
  }
}
</style>
