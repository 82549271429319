<template>
  <div class="flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full bg-[#0000007a]">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
      <div class="relative bg-white rounded-lg shadow">
        <div class="p-6 text-center">
          <svg class="mx-auto mb-4 w-14 h-14 text-gray-400"
               fill="none"
               stroke="currentColor"
               viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg"
          ><path stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke-width="2"
                 d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          /></svg>
          <h3 class="mb-5 text-lg font-normal text-gray-500">
            {{ msg }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    msg: {
      type: String,
      required: true,
    },
  },
};
</script>
