<template>
  <app-layout heading="sign up"
              :breadcrumb="breadcrumb"
  >
    <div class="w-full text-black flex justify-center items-center my-10">
      <div class="w-[95%] lg:w-[40%] flex justify-center items-center flex-col">
        <div class="w-full flex justify-start my-5">
          <span v-show="isError"
                class="text-red-600 capitalize"
          >{{
            errorMsg
          }}</span>
        </div>
        <div class="h-[20%] w-full flex flex-col justify-end items-center my-5">
          <div class="relative flex w-full items-center">
            <input
              v-model="formData.first_name"
              :class="getClass(v$.formData.first_name.$error)"
              type="text"
              :placeholder="
                v$.formData.first_name.$error
                  ? v$.formData.first_name.$errors[0].$message
                  : ' first Name'
              "
              class="w-full border-b-[2px] border-[#aaa] outline-none p-2 md:p-4 pl-10 md:pl-14 box-border duration-300 focus:boder-[#eb6d6d] rounded-lg focus:shadow-slate-500 focus:shadow-xl"
            >

            <img
              src="../../assets/Image/RegisterImg-icon/user-icon-2.png"
              class="mr-2 absolute left-0 pl-3"
            >
          </div>
        </div>
        <div class="h-[20%] w-full flex flex-col justify-end items-center my-5">
          <div class="relative flex w-full items-center">
            <input
              v-model="formData.last_name"
              :class="getClass(v$.formData.last_name.$error)"
              type="text"
              :placeholder="
                v$.formData.last_name.$error
                  ? v$.formData.last_name.$errors[0].$message
                  : 'lastname'
              "
              class="w-full border-b-[2px] border-[#aaa] outline-none p-2 md:p-4 pl-10 md:pl-14 box-border duration-300 focus:boder-[#eb6d6d] rounded-lg focus:shadow-slate-500 focus:shadow-xl"
            >

            <img
              src="../../assets/Image/RegisterImg-icon/user-icon-2.png"
              class="mr-2 absolute left-0 pl-3"
            >
          </div>
        </div>

        <div class="h-[20%] w-full flex flex-col justify-end items-center my-5">
          <div class="w-full">
            <small
              v-for="(error, index) of v$.formData.password.$errors"
              :key="index"
              class="text-red-600 pl-3"
            >
              {{ error.$message }}
            </small>
          </div>
          <div class="relative flex w-full items-center">
            <input
              v-model="formData.password"
              :type="passwordInputType"
              :class="getClass(v$.formData.password.$error)"
              class="w-full border-b-[2px] border-[#aaa] outline-none p-2 md:p-4 pl-10 md:pl-14 box-border duration-300 focus:boder-[#eb6d6d] rounded-lg focus:shadow-slate-500 focus:shadow-xl"
              :placeholder="
                v$.formData.password.$error
                  ? v$.formData.password.$errors[0].$message
                  : 'password'
              "
            >
            <img
              src="../../assets/Image/RegisterImg-icon/password-icon.png"
              class="mr-2 absolute left-0 px-2"
            >
            <img
              :src="require(`@/assets/Image/RegisterImg-icon/${eyeImag}`)"
              class="mr-2 absolute w-[40px] right-0 px-2 cursor-pointer"
              @click="togglePasswordVisibility"
            >
          </div>
        </div>

        <div class="h-[20%] w-full flex flex-col justify-end items-center my-5">
          <div class="w-full">
            <small
              v-for="(error, index) of v$.formData.c_password.$errors"
              :key="index"
              class="text-red-600 pl-3"
            >
              {{ error.$message }}
            </small>
          </div>

          <div class="relative flex w-full items-center">
            <input
              v-model="formData.c_password"
              :type="passwordInputType"
              :class="getClass(v$.formData.c_password.$error)"
              class="w-full border-b-[2px] border-[#aaa] outline-none p-2 md:p-4 pl-10 md:pl-14 box-border duration-300 focus:boder-[#eb6d6d] rounded-lg focus:shadow-slate-500 focus:shadow-xl"
              :placeholder="
                v$.formData.c_password.$error
                  ? v$.formData.c_password.$errors[0].$message
                  : 'confrim password'
              "
            >
            <img
              src="../../assets/Image/RegisterImg-icon/password-icon.png"
              class="mr-2 absolute left-0 px-2"
            >
            <img
              :src="require(`@/assets/Image/RegisterImg-icon/${eyeImag}`)"
              class="mr-2 absolute w-[40px] right-0 px-2 cursor-pointer"
              @click="togglePasswordVisibility"
            >
          </div>
        </div>
        <div class="h-[20%] w-full flex flex-col justify-end items-center my-5">
          <div class="w-full">
            <small
              v-for="(error, index) of v$.formData.email.$errors"
              :key="index"
              class="text-red-600 pl-3"
            >
              {{ error.$message }}
            </small>
          </div>
          <div class="relative flex w-full items-center">
            <input
              v-model="formData.email"
              :class="getClass(v$.formData.email.$error)"
              type="text"
              class="border-b-[2px] w-full border-[#aaa] outline-none box-border p-2 md:p-4 pl-10 md:pl-14 duration-300 focus:boder-[#eb6d6d] rounded-lg focus:shadow-slate-500 focus:shadow-xl"
              :placeholder="
                v$.formData.email.$error
                  ? v$.formData.email.$errors[0].$message
                  : 'email'
              "
              @keyup.enter="submitForm"
            >
            <img
              src="../../assets/Image/RegisterImg-icon/email-icon.png"
              class="mr-2 absolute left-0 px-2"
            >
          </div>
        </div>
        <div class="mt-12 w-full flex justify-between items-center">
          <span
            class="underline text-sm cursor-pointer"
            @click="$router.push({ name: 'Signin' })"
          >Already have account</span>
          <button
            class="bg-secondary px-4 py-2 text-white rounded-lg"
            @click="submitForm"
          >
            Signup
            <svg
              class="fill-[#ffffff] w-[15px] ml-2 inline"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </app-layout>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, email, sameAs, minLength } from "@vuelidate/validators";
import "mosha-vue-toastify/dist/style.css";
import {
  showToast,
  togglePasswordVisibility,
  isNumeric,
  getClass,
} from "../Utility/utils";
export default {
  name: "Signup",
  data () {
    return {
      v$: useValidate(),
      breadcrumb: ["Home", "Signup"],
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        c_password: "",
      },
      isError: false,
      isLoading: false,
      errorMsg: "",
      eyeImag: "hide-password.png",
      passwordInputType: "password",
    };
  },
  validations () {
    return {
      formData: {
        first_name: { required },
        last_name: { required },
        password: {
          required,
          minLength: minLength(8),
        },
        c_password: { sameAsPassword: sameAs(this.formData.password) },
        email: { required, email },
      },
    };
  },
  computed: {
    getClass,
  },
  methods: {
    togglePasswordVisibility,
    isNumeric,
    async submitForm () {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        // run if validation failed
        return;
      }
      this.isLoading = true;
      // run if validation success
      const isNumeric = this.isNumeric(this.formData.password);

      if (!isNumeric) {
        let response = await this.$store.dispatch("signup", this.formData);
        if (response.status === 200) {
          this.isError = false;
          this.$router.push({ name: "Signin" });
          showToast("Success", "Your account has been created, Please Login");
          this.isLoading = false;
        } else if (response.status === 400) {
          if (response.data.email) {
            this.isLoading = false;
            this.isError = true;
            this.errorMsg = "user already exist";
          }
          if (response.data.password && !response.data.email) {
            this.isLoading = false;
            this.isError = true;
            this.errorMsg = response.data.password[0];
          }
        } else if (signInresponse.status === 500) {
          this.isLoading = false;
          this.isError = true;
          this.errorMsg = "Internal Server Error";
        }
      }
    },
  },
};
</script>
