<template>
  <Spinner v-if="isLoading" />
  <div
    :class="{ overlay: scrolled }"
    class="fixed top-0 left-0 w-full max-h-[10vh] transition duration-500 z-50"
    :style="{ backgroundColor: background }"
  >
    <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
      <div v-if="toggle">
        <ul
          class="absolute top-0 right-0 bg-black lg:hidden w-2/3 h-screen ml-auto pt-20"
        >
          <li
            v-show="isLogin"
            class="ml-12 font-lighter tracking-widest uppercase py-2 text-left transition hover:text-secondary"
          >
            <span class="block text-[#007aff]">{{ userName }}</span>
          </li>
          <li
            v-show="isLogin"
            class="ml-12 font-lighter tracking-widest uppercase py-2 text-left transition hover:text-secondary"
          >
            <span class="block text-secondary"
                  @click="showModal"
            >Offers</span>
          </li>

          <li
            v-for="item in items"
            id="nav-link"
            :key="item.name"
            class="ml-12 font-lighter tracking-widest uppercase py-2 text-left transition hover:text-secondary"
            :class="[item.class, getClass(item)]"
          >
            <router-link
              :to="{ path: item.path || '/', hash: item.url }"
              @click="toggleNavbar()"
            >
              {{ item.name }}
            </router-link>
          </li>
          <li
            class="ml-12 font-lighter tracking-widest uppercase py-2 text-left transition text-[#007aff]"
          >
            <router-link v-show="!isLogin"
                         to="/signin"
                         class="text-[#007aff]"
            >
              Signin
            </router-link>
            <router-link
              v-show="isLogin"
              to="/services"
              class="block mb-4 text-white"
            >
              My Account
            </router-link>

            <router-link
              v-show="isLogin"
              to="/"
              class="text-white"
              @click="signout"
            >
              signout
            </router-link>
          </li>
        </ul>
      </div>
      <div class="py-4 px-6">
        <div class="flex justify-between">
          <router-link to="/"
                       class="logo uppercase w-10 flex"
          >
            <img
              class="h-[45px]"
              src="@/assets/Icon-Symbol/Symbol-logo-white.png "
              u
            >
            <div
              class="flex flex-col justify-center font-compiler-300 tracking-widest"
            >
              <span class="text-sm font-thin px-2">Alessandro</span>
              <span class="text-sm font-thin px-2">Cavagnola</span>
            </div>
          </router-link>

          <ul class="flex items-center">
            <li
              class="ml-8 font-normal uppercase hidden lg:block text-secondary active:font-normal cursor-pointer"
              @click="showModal"
            >
              Offers
            </li>
            <li
              v-for="item in items"
              id="nav-link"
              :key="item.name"
              class="ml-8 font-normal uppercase hidden lg:block hover:text-secondary active:font-normal"
              :class="[item.class, getClass(item)]"
            >
              <router-link
                :to="{ path: item.path || '/', hash: item.url }"
                class="flex"
              >
                <div
                  v-if="item.isCart"
                  class="flex flex-row-reverse w-full relative"
                >
                  <div
                    v-if="cartTotal > 0"
                    class="absolute top-0 right-0 -mt-2 -mr-2 px-1 text-xs rounded-full bg-secondary bg-opacity-95 text-white"
                  >
                    {{ cartTotal }}
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-5 h-5"
                  >
                    <circle cx="9"
                            cy="21"
                            r="1"
                    />
                    <circle cx="20"
                            cy="21"
                            r="1"
                    />
                    <path
                      d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                    />
                  </svg>
                </div>
                <div v-else>
                  {{ item.name }}
                </div>
              </router-link>
            </li>
            <li
              class="ml-8 font-normal uppercase hidden lg:block active:font-normal text-[#007aff]"
            >
              <router-link
                v-show="!isLogin"
                class="text-[#007aff]"
                to="/signin"
              >
                Signin
              </router-link>
            </li>
            <span
              v-show="isLogin"
              class="font-normal capitalize text-lg hidden lg:block active:font-normal truncate text-[#007aff]"
            >{{ userName }}</span>
            <nav v-show="isLogin"
                 class="hidden lg:block"
            >
              <ul class="cursor-pointer">
                <li>
                  <div
                    id="userAvatar"
                    class="flex items-center"
                    @click="showDropDown"
                  >
                    <img
                      src="@/assets/Icon-Symbol/down-arrow-svgrepo-com.svg"
                      alt="arrow-menu-icon"
                      class="w-[20px] h-[20px] mt-1 ml-1"
                    >
                  </div>
                  <ul
                    id="drop-down"
                    class="hidden absolute rounded-md w-[200px] mt-2 ab text-black bg-white"
                  >
                    <router-link
                      to="/services"
                      class="flex items-center py-2 px-4 rounded-md cursor-pointer hover:bg-[#e7e3e3]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        class="w-[20px] h-[25px] inline-block"
                      >
                        <path
                          d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"
                        />
                      </svg>

                      <label class="ml-4 cursor-pointer uppercase">My Account</label>
                    </router-link>
                    <router-link
                      to="/"
                      class="flex items-center py-2 px-4 rounded-md cursor-pointer hover:bg-[#e7e3e3]"
                      @click="signout"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        class="w-[20px] fill-[#4d4d4d] inline-block"
                      >
                        <path
                          d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z"
                        />
                      </svg>

                      <label class="ml-4 cursor-pointer uppercase">Signout</label>
                    </router-link>
                  </ul>
                </li>
              </ul>
            </nav>

            <li
              class="font-bold uppercase block lg:hidden hover:text-secondary"
            >
              <div @click="toggleNavbar()">
                <img
                  class="invert cursor-pointer"
                  :src="require(`@/assets/Icon-Symbol/${menuIcon}`)"
                  alt="menu-icon"
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
export default {
  name: "AppNavbar",
  props: {
    background: {
      type: String,
      required: false,
      default: "#0000000",
    },
  },
  data () {
    return {
      scrolled: false,
      toggle: false,
      currentPage: location.href,
      menuIcon: "menu.svg",
      isLogged: false,
      number: 1,
      items: [
        { name: "home", url: "#home", class: "home" },
        { name: "about", url: "#about", class: "about" },
        { name: "services", url: "#service", class: "service" },
        // { name: "shop", url: "#shop", class: "shop" },
        // { name: "blog", url: "#blog", class: "blog" },
        { name: "testimonial", url: "#testimonial", class: "testimonial" },
        { name: "social", url: "#social", class: "social" },
        { name: "Contact", url: "#contact", class: "contact" },
        // { name: "cart", path: "/cart", url: "", class: "cart", isCart: true },
      ],
      isLoading: false,
      userName: "",
    };
  },
  computed: {
    getClass () {
      return (item) => {
        return {
          active: this.currentPage.includes(item.path),
        };
      };
    },
    cartTotal () {
      return this.$store.getters.cartTotal;
    },
    isLogin () {
      return this.$store.getters.isLogin;
    },
  },
  mounted () {
    return JSON.parse(localStorage.getItem("cartItemsCount"));
  },
  async created () {
    if (this.isLogin) {
      await this.$store.dispatch("profile");
      let response = this.$store.getters.profile;
      let username_value = `Welcome ${response.first_name}`;
      this.userName = username_value;
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    async showModal () {
      this.toggle = false;
      this.menuIcon = this.menuIcon === "menu.svg" ? "x.svg" : "menu.svg";
      await this.$store.dispatch("showPopModal", true);
    },
    showDropDown () {
      const div = document.getElementById("drop-down");
      if (div.classList.contains("hidden")) {
        div.classList.remove("hidden");
      } else {
        div.classList.add("hidden");
      }
    },
    toggleNavbar () {
      this.toggle = !this.toggle;
      this.menuIcon = this.menuIcon === "menu.svg" ? "x.svg" : "menu.svg";
    },
    handleScroll () {
      const sections = document.querySelectorAll("section");
      const navLink = document.querySelectorAll("#nav-link");

      this.scrolled = scrollY > 60;

      let current = "";
      sections.forEach((section) => {
        let sectionTop = section.offsetTop - 400;
        let sectionHeight = section.offsetHeight;
        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          current = section.getAttribute("id");
        }
        navLink.forEach((link) => {
          link.classList.remove("active");
          if (link.classList.contains(current)) {
            link.classList.add("active");
          }
        });
      });
    },
    async signout () {
      this.isLoading = true;
      await this.$store.dispatch("signout");
      this.showToast("info", "Signout Successfully!");
      this.isLoading = false;
    },
    showToast (type, msg) {
      createToast(
        {
          description: msg,
        },
        {
          position: "bottom-right",
          timeout: 1000,
          type: type,
          hideProgressBar: false,
        },
      );
    },
  },
};
</script>

<style scoped>
.overlay {
  background: rgba(0, 0, 0, 0.88);
  transition: 0.2s ease-in-out;
}
.active {
  color: #f43738;
}

/* nav ul li:hover > ul {
  display: inherit;
} */

@media (min-width: 768px) and (max-width: 1600px) {
  #drop-down {
    right: 1%;
  }
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
