<template>
  <appSection
    id="about"
    heading="About me"
    bg-color="bg-black"
    headingcolor="text-black"
    descriptioncolor="text-black"
    description="Alessandro is a professional bodybuilder. Here you read more about him."
  >
    <div class="flex md:flex-row flex-col text-gray-300 items-center">
      <div
        class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 relative border-8 border-double border-gray-400 mb-4"
      >
        <img
          class="object-cover w-full h-96 md:h-[36rem] rounded shadow-lg"
          src="@/assets/Image/HomeImg/alessandro.jpg"
        >
      </div>
      <div
        class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 text-center"
      >
        <h1
          class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-200"
        >
          Who I am?
        </h1>
        <p class="mb-8 leading-relaxed">
          Alessandro, an Italian athlete born in Brescia on 25 May 1991, began
          his path in the fitness and bodybuilding field as a personal trainer,
          then approached the competitive world later, obtaining the title of a
          professional athlete in the Men's Physique category on 27 October 2018
          in Germany.
        </p>
        <p class="mb-8 leading-relaxed">
          Parallel to his competitive path, Alessandro begins to be contacted by
          various photographers in the fitness field both in Italy and by many
          parties around the world, thanks also to these works at an
          international level, growth on social media also rises, which then led
          to interest by several brands to initiate sponsorship contracts as an
          influencer and professional athlete.
        </p>
        <p class="mb-8 leading-relaxed">
          At the moment, in addition to continuing his career as a fitness model
          and influencer, he is pursuing the goal of obtaining the qualification
          for Mr Olympia, the most coveted bodybuilding competition in the
          world. The objective that arrives in 2021 where he wins the victory of
          two consecutive Pro shows and qualifies for Mr Olympia 2022.
        </p>
        <div class="flex items-center mt-2 -mx-2">
          <a
            class="mx-2"
            href="https://www.instagram.com/_alessandrocavagnola/"
            target="_blank"
            aria-label="Instagram"
          >
            <svg
              class="w-5 h-5 text-white fill-current hover:text-red-800"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </a>
          <a
            class="mx-2"
            href="https://www.facebook.com/Alessadro.Cavagnola/"
            target="_blank"
            aria-label="Facebook"
          >
            <svg
              class="w-5 h-5 text-white fill-current hover:text-red-800"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M426.8 64H85.2C73.5 64 64 73.5 64 85.2v341.6c0 11.7 9.5 21.2 21.2 21.2H256V296h-45.9v-56H256v-41.4c0-49.6 34.4-76.6 78.7-76.6 21.2 0 44 1.6 49.3 2.3v51.8h-35.3c-24.1 0-28.7 11.4-28.7 28.2V240h57.4l-7.5 56H320v152h106.8c11.7 0 21.2-9.5 21.2-21.2V85.2c0-11.7-9.5-21.2-21.2-21.2z"
              />
            </svg>
          </a>

          <a
            class="mx-2"
            href="https://www.youtube.com/user/VGGHKJH"
            target="_blank"
            aria-label="youtube"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 text-white fill-current hover:text-red-800"
              viewBox="0 0 576 512"
            >
              <path
                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </appSection>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>
